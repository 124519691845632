import Container, { Service } from "typedi";
import { IChatRoomParticipant } from "../../types/chat";
import { ApiService } from "../api/api.service";

@Service()
export class ChatService extends ApiService {
  constructor() {
    super();
    Container.set(ChatService, this);
  }

  async getChatRooms({
    participant_id,
  }: {
    participant_id: string;
  }) {
    return await this.get(
      "/chat/rooms", { participant_id },
    );
  }

  async createChatRoomWithSupport(data: IChatRoomParticipant) {
    return await this.post(
      "/chat/room/support", data,
    );
  }
}
