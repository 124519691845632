import { CUSTOM_PHYX_BANNER_URI } from "@env";
import { Ionicons } from "@expo/vector-icons";
import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Card } from "react-native-paper";
import { APP_IMAGES } from "../../../assets/images";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import useSize from "../../hooks/useSize";
import { AuthContext, ModalData, UserData } from "../../provider/AuthProvider";
import Loader from "../../screens/utils/Loading";
import {
  CustomWorkouts,
  IProgram,
  ProgramType,
} from "../../types/program.types";
import CardContent from "./CardContent";
import EmptyCard from "./EmptyCard";
import { getData, handleRemovePhyx } from "./carouselCommon";
enum EnumType {
  removePhyx = "removePhyx",
}
export default function WebCarousel({ navigation }: any) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const [data, setData] = useState<any[]>([]);
  const { isMiniMobile } = useSize();

  const { userData, setUserLocalData, allPrograms, setShowModals, setModalData } = useContext(
    AuthContext,
  ) as any;
  const [loading, setLoading] = useState(false);
  const [currentPhyx, setCurrentPhyx] = useState<IProgram | CustomWorkouts>();

  const removePhyx = async (
    item: CustomWorkouts | IProgram,
    userData: UserData,
    setUserLocalData: any,
  ) => {
    const action1 = async () => {
      setLoading(true);
      handleRemovePhyx(item, userData as UserData, setUserLocalData).then(
        () => {
          setLoading(false);
        },
      );
    };

    const action2 = () => {
      if (setShowModals) {
        setShowModals(false);
      }
    };

    const modalData = getModalData(EnumType.removePhyx, action1, action2);
    if (setModalData) {
      setModalData(modalData);
    }
    if (setShowModals) {
      setShowModals(true);
    }
  };

  const getModalData = (
    type: EnumType,
    button1Action: any,
    button2Action: any,
  ) => {
    const modalData: ModalData = {
      headerImage: "https://phyxableprograms.s3.amazonaws.com/prevent.svg",
      bodyImage: "",
      footerImage: "",
      title: "Are you sure?",
      bodyTitle:
        type === EnumType.removePhyx
          ? "Are you sure you want to remove this program?"
          : "",
      bodyList: [],
      button1: "Yes",
      button2: "Close",
      type: "notification",
      button1Action: () => button1Action(),
      button2Action: () => button2Action(),
    };

    return modalData;
  };

  useEffect(() => {
    setLoading(true);
    getData(
      userData as UserData,
      setData,
      allPrograms as IProgram[],
      setUserLocalData,
    ).then((data) => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setCurrentPhyx(userData?.currentProgram);
  }, [userData.currentProgram]);

  const handleProgramPress = (item: IProgram) => {
    const programData = {
      ...userData,
      currentProgram: item,
    };

    setUserLocalData(programData);

    navigation.navigate(RouteNames.PROGRAM_OVERVIEW, {
      id: item.id,
      image: item.images.banner || CUSTOM_PHYX_BANNER_URI,
      data: item,
    });
  };

  const [coverHeight, setCoverHeight] = useState(0);

  const onLayout = (event: any) => {
    const { height } = event.nativeEvent.layout;
    setCoverHeight(height);
  };

  const renderProgram = ({
    item,
    index,
  }: {
    item: IProgram;
    index: number;
  }) => {
    return (
      <TouchableOpacity
        key={index + Math.random()}
        style={{
          flex: 1,
        }}
        onPress={() => handleProgramPress(item)}
      >
        <Ionicons
          name="close"
          size={40}
          color={colors.primaryColor}
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
            zIndex: 100,
          }}
          onPress={() =>
            removePhyx(item, userData as UserData, setUserLocalData)
          }
        />
        <Card
          elevation={5}
          style={{
            width: isTablet ? width * 0.6 : width * 0.85,
            flexGrow: 1,
            flex: 1,
            height: "100%",
            minHeight: 300,
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: "white",
            position: "relative",
          }}
          contentStyle={{
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Card.Cover
            source={{ uri: item?.images?.banner || CUSTOM_PHYX_BANNER_URI }}
            style={{ height: isMiniMobile ? 120 : 195, maxHeight: "50%" }}
            onLayout={onLayout}
          />
          <Image
            resizeMethod="resize"
            resizeMode="contain"
            style={{
              position: "absolute",
              top: coverHeight / 2,
              left: "50%",
              width: width * 0.15,
              height: height * 0.2,
              transform: [
                { translateX: -width * 0.075 },
                { translateY: -height * 0.1 },
              ],
            }}
            source={APP_IMAGES.playIcon}
          />
          <CardContent
            title={item?.title || ""}
            text={item?.overview || ""}
            often={item?.often}
          />
        </Card>
      </TouchableOpacity>
    );
  };

  const renderCustomProgram = ({
    item,
    index,
  }: {
    item: CustomWorkouts;
    index: number;
  }) => {
    return (
      <TouchableOpacity
        key={Math.random()}
        style={{
          flex: 1,
        }}
        onPress={() => {
          // Set the current program
          //@ts-ignore
          setUserLocalData({ ...userData, currentProgram: item });
          navigation.navigate(RouteNames.CUSTOMPHYX_OVERVIEW, {
            id: item._id,
            image: CUSTOM_PHYX_BANNER_URI,
            data: item,
          });
        }}
      >
        <Ionicons
          name="close"
          size={40}
          color={colors.primaryColor}
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
            zIndex: 100,
          }}
          onPress={() => {
            removePhyx(item, userData as UserData, setUserLocalData);
          }}
        />
        <Card
          elevation={5}
          style={{
            width: isTablet ? width * 0.6 : width * 0.85,
            flexGrow: 1,
            flex: 1,
            height: "100%",
            minHeight: 300,
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: "white",
            position: "relative",
          }}
          contentStyle={{ flexGrow: 1, height: "100%" }}
        >
          <Card.Cover
            source={{ uri: CUSTOM_PHYX_BANNER_URI }}
            style={{ height: isMiniMobile ? 120 : 195, maxHeight: "50%" }}
            onLayout={onLayout}
          />
          <Image
            resizeMethod="resize"
            resizeMode="contain"
            style={{
              position: "absolute",
              top: coverHeight / 2,
              left: "50%",
              width: width * 0.15,
              height: height * 0.2,
              transform: [
                { translateX: -width * 0.075 },
                { translateY: -height * 0.1 },
              ],
            }}
            source={APP_IMAGES.playIcon}
          />
          <CardContent
            title={item?.program_name || ""}
            text={item?.program_overview || ""}
            isCustom
          />
        </Card>
      </TouchableOpacity>
    );
  };

  return loading ? (
    <View
      style={{
        flex: 1,
        marginTop: "25%",
      }}
    >
      <Loader />
    </View>
  ) : (
    <View
      style={{
        paddingVertical: 20,
        backgroundColor: "white",
        height: "100%",
      }}
    >
      {currentPhyx ? (
        currentPhyx.type === ProgramType.Custom ? (
          renderCustomProgram({
            item: userData?.currentProgram as CustomWorkouts,
            index: 0,
          })
        ) : (
          renderProgram({
            item: userData?.currentProgram as IProgram,
            index: 0,
          })
        )
      ) : (
        <View
          style={{
            alignSelf: "center",
            padding: 10,
          }}
        >
          <EmptyCard navigation={navigation} />
        </View>
      )}
    </View>
  );
}
