import Container, { Service } from "typedi";
import { ApiService } from "../api/api.service";

@Service()
export class VideoChatService extends ApiService {
    private readonly VIDEO_CHAT_BASE_URL = "/videoChat";
    constructor() {
        super();
        Container.set(VideoChatService, this);
    }

    // Get videoCall Room url which is valid for 2 hour
    async getVideoCallRoom(roomMode: RoomMode) {
        return await this.post(`${this.VIDEO_CHAT_BASE_URL}/meetings`, roomMode);
    }

    // delete the room
    async deleteVideoCallRoom(meetingId: string) {
        return await this.delete(`${this.VIDEO_CHAT_BASE_URL}/meetings/${meetingId}`);
    }
    //get all the groupMeetings
    async getGroupMeetings() {
        return await this.get(`/groupMeetings`);
    }
}

export const videoChatService = new VideoChatService();

export interface RoomMode {
    roomMode: string;
    roomPrefix: string;
}
