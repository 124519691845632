import { Slider } from "@miblanchard/react-native-slider";
import React, { useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import RenderImage from "../../components/Common/renderImage";
import Button from "../../components/Home/button";
import { BackButton } from "../../components/Program/backButton";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";

export default function ProgramVAS({ navigation, route }: any) {
  const { id, image, level, session, psfs, data } = route.params;

  const [sliderValue, setSliderValue] = useState(0);
  const [space, setSpace] = useState(0);
  const [dummyWidth, setDummyWidth] = useState(0);
  const [vasText, setVasText] = useState("No pain");
  const [sliderMoved, setSliderMoved] = useState(false);
  const [vasImage, setVasImage] = useState(
    "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/great-blob.svg",
  );

  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleSliderChange = (value: any[]) => {
    setSliderMoved(true);
    setSliderValue(value[0]);
    handleVAS(value[0]);
  };

  //Sets the width of the slider component
  const handleSliderViewWidth = (event: {
    nativeEvent: { layout: { width: any } };
  }) => {
    const { width } = event.nativeEvent.layout;
    const _width = width / 12;
    setSpace(_width);
  };

  const handleConfirm = () => {
    if (psfs.length !== 0) {
      navigation.navigate(RouteNames.PSFS, {
        id: id,
        vas: sliderValue,
        psfs: psfs,
        image: image,
        level: level,
        session: session,
        data: data,
      });
    } else {
      navigation.navigate(RouteNames.PLAYLIST, {
        id: id,
        vas: sliderValue,
        image: image,
        level: level,
        session: session,
        data: data,
      });
    }
  };

  //Styles for dummy slider component
  const sliderStyle = {
    sliderDummy: {
      width: isTablet ? width * 0.8 : width * 0.9,
      height: 15,
      position: "absolute",
    },
    sliderReal: {
      height: 15,
      borderRadius: 50,
    },
  };

  //Change the VAS pain text and VAS image depending on the slider value
  const handleVAS = (value: any) => {
    switch (value) {
      case 0:
        setVasText("No pain");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/great-blob.svg",
        );
        break;
      case 1:
        setVasText("Hardly notice pain");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/good-blob.svg",
        );
        break;
      case 2:
        setVasText("Notice pain, does not interfere with activities");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/good-blob.svg",
        );
      case 3:
        setVasText("Sometimes distracts me");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/good-blob.svg",
        );
        break;
      case 4:
        setVasText("Distracts me, can do usual activities");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/ok-blob.svg",
        );
        break;
      case 5:
        setVasText("Interrupts some activities");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/ok-blob.svg",
        );
        break;
      case 6:
        setVasText("Hard to ignore, avoid usual activities");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/ok-blob.svg",
        );
        break;
      case 7:
        setVasText("Focus of attention, prevents doing daily activities");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob.svg",
        );
        break;
      case 8:
        setVasText("Awful, hard to do anything");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-two.svg",
        );
        break;
      case 9:
        setVasText("Can't bear the pain, unable to do anything");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-two.svg",
        );
        break;
      case 10:
        setVasText("As bad as it could be, nothing else matters");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-three.svg",
        );
        break;
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <BackButton handleGoBackCallback={handleGoBack} />
        <View style={styles.content}>
          <Text style={styles.title}>How much pain do you feel?</Text>

          <RenderImage
            uri={vasImage}
            size={{
              width: "50%",
              height: height * 0.35,
            }}
            style={{marginTop: 20}}
          />
          <Text style={styles.vasText}>{vasText}</Text>

          <View style={styles.sliderTitleView}>
            <Text style={styles.sliderTitle}>No Pain</Text>
            <Text style={styles.sliderTitle}>Worst</Text>
          </View>
          <View>
            <Slider
              value={[sliderValue]}
              onValueChange={handleSliderChange}
              animateTransitions
              minimumValue={0}
              maximumValue={10}
              step={1}
              trackClickable={true}
              minimumTrackTintColor={colors.primaryLightColor}
              maximumTrackTintColor={colors.primaryColor}
              thumbStyle={styles.thumb}
              trackStyle={styles.track}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: isTablet ? (width - 40) * 0.8 : (width - 40) * 0.9,
              }}
            >
              {Array.from(Array(11).keys()).map((key) => {
                const textColor =
                  sliderValue === key ? colors.primaryColor : colors.textColor;
                return (
                  <Text
                    style={{ color: textColor, marginRight: space - 4 }}
                    key={key}
                  >
                    {key}
                  </Text>
                );
              })}
            </View>
          </View>
          <Button
            text="CONFIRM"
            backgroundColor={sliderMoved ? colors.primaryColor : colors.grey}
            disabled={sliderMoved ? false : true}
            customStyle={{
              alignSelf: "center",
              marginTop: 30,
              marginBottom: 20,
              paddingHorizontal: 40,
            }}
            onPressCallback={handleConfirm}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    height: "100%",
    paddingHorizontal: 10,
  },
  content: {
    paddingHorizontal: 20,
    alignItems: "center",
    marginTop: 20,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 28,
  },
  subTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  image: {
    marginTop: 20,
  },
  sliderTitleView: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    // width: width + 20,
    marginTop: 20,
    marginBottom: 10,
  },
  sliderTitle: {
    color: colors.textColor,
    fontWeight: "700",
    left: 0,
  },
  sliderView: {
    // width: WIDTH,
    justifyContent: "center",
    flexDirection: "column",
    height: 15,
    marginBottom: 10,
  },
  dummySliderView: {
    flexDirection: "row",
  },
  realSliderView: {
    position: "absolute",
    // width: WIDTH,
  },
  vasText: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 21,
    minHeight: 52,
  },
  thumb: {
    backgroundColor: colors.primaryColor,
    borderRadius: 5,
    height: 30,
    width: 10,
  },
  track: {
    backgroundColor: colors.grey,
    borderRadius: 5,
    height: 10,
  },
});
