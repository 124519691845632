import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import Button from "../../components/Home/button";
import { BackButton } from "../../components/Program/backButton";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { RootStackParamList } from "../../types/navigation";
import {
  ExerciseType,
  IProgram,
  Interstitial,
} from "../../types/program.types";

export type StopInterstitialRouteParams = {
  id: string;
  image: string;
  interstitial: Interstitial;
  vas: ExerciseType[];
  psfs: ExerciseType[];
  data: IProgram;
  level: number;
  session: number;
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.STOP_INTERSTITIAL
>;

export default function StopInterstitial({ navigation, route }: Props) {
  const { id, image, interstitial, vas, psfs, level, session, data } =
    route.params;

  const handleButton = () => {
    if (interstitial) {
      navigation.navigate(RouteNames.INTERSTITIAL, {
        id: id,
        image: image,
        interstitial: interstitial,
        level: level,
        session: session,
        vas: vas,
        psfs: psfs,
        data: data,
      });
    } else if (vas.length !== 0) {
      navigation.navigate(RouteNames.VAS, {
        id: id,
        image: image,
        level: level,
        session: session,
        psfs: psfs,
        data: data,
      });
    } else {
      navigation.navigate(RouteNames.PSFS, {
        id: id,
        image: image,
        level: level,
        session: session,
        psfs: psfs,
        data: data,
      });
    }
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <View style={styles.container}>
        <BackButton handleGoBackCallback={handleGoBack} />
        <View style={styles.content}>
          <Text style={styles.text}>How are you feeling today?</Text>
        </View>
        <ScrollView
          contentContainerStyle={styles.scroll}
        >
          <View>
            <View style={styles.content}>
              <Text style={styles.intText}>
                If you are experiencing any of the following symptoms, please
                take a rest day and return to the Phyx when you are feeling
                better.
              </Text>
            </View>

            <View style={styles.pointContent}>
              <Text style={styles.points}>Low energy</Text>
              <Text style={styles.points}>Dizziness</Text>
              <Text style={styles.points}>Shortness of breath</Text>
              <Text style={styles.points}>Chest pain</Text>
              <Text style={styles.points}>Fever</Text>
            </View>

            <View style={styles.pointContent}>
              <Text style={styles.subText}>
                If at any time during the session you start to feel unwell or
                experience dizziness, shortness of breath, or chest pain, click
                the STOP button to end the session.
              </Text>
            </View>
          </View>

          <View>
            <Button
              text="Proceed to Session"
              backgroundColor={colors.secondaryColor}
              fontSize={20}
              customStyle={{
                alignSelf: "center",
                marginTop: 20,
                width: 250,
                height: 50,
                borderRadius: 50,
              }}
              onPressCallback={handleButton}
            />

            <Button
              text="Stop"
              backgroundColor="#ffffff"
              color={colors.secondaryColor}
              fontSize={20}
              customStyle={{
                alignSelf: "center",
                marginTop: 10,
                width: 250,
                height: 50,
                borderRadius: 50,
                borderWidth: 1,
                borderColor: colors.secondaryColor,
              }}
              onPressCallback={() => navigation.navigate(RouteNames.HOME)}
            />
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: "100%",
    paddingHorizontal: 10,
  },
  scroll: {
    flexGrow: 1,
    justifyContent: "space-between",
    marginBottom: 24,
  },
  pointContent: {
    marginTop: 16,
    marginLeft: 12,
    marginRight: 12,
  },
  content: {
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 24,
    color: colors.secondaryColor,
    fontWeight: "700",
    textAlign: "center",
  },
  points: {
    fontSize: 20,
    marginTop: 5,
    color: colors.primaryColor,
    fontWeight: "700",
    textAlign: "center",
  },
  intText: {
    fontSize: 16,
    color: colors.secondaryColor,
    fontWeight: "300",
    textAlign: "center",
    marginTop: 10,
  },
  subText: {
    fontSize: 14,
    color: colors.secondaryColor,
    fontWeight: "200",
    textAlign: "center",
    marginTop: 10,
  },
  image: {
    width: 230,
    height: 230,
    marginTop: 30,
  },
});
