import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { SafeAreaView, View } from "react-native";
import { Text } from "react-native-paper";
import BackArrow from "../../../components/Chat/backArrow";
import Header from "../../../components/Common/HeaderComponent";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";

export default function NotificationSettings({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const handleGoBack = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.ACCOUNT_MAIN }],
    });
  };
  return (
    <SafeAreaView style={{flex: 1}}>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[colors.buttonWhite, colors.buttonWhite]}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Header navigation={navigation} />
        <BackArrow handleGoBackCallback={handleGoBack} />
        <View style={{}}>
          <Text
            style={{
              fontWeight: "700",
              color: colors.blueSettings,
              fontSize: 24,
              marginBottom: 10,
              alignSelf: "center",
            }}
          >
            NOTIFICATIONS
          </Text>
        </View>
        <View>
          <Text
            style={{
              fontWeight: "500",
              color: colors.blueSettings,
              fontSize: 24,
              marginBottom: 10,
              marginLeft: 20,
              alignSelf: "flex-start",
            }}
            onPress={() => {
              navigation.navigate(RouteNames.PROGRAM_REMINDERS);
            }}
          >
            Program Reminders
          </Text>
        </View>
      </LinearGradient>
    </SafeAreaView>
  );
}
