import { Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect } from "react";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Container from "typedi";
import { UserService } from "../../../../services/user/user.service";
import BackArrow from "../../../components/Chat/backArrow";
import Header from "../../../components/Common/HeaderComponent";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { AuthContext } from "../../../provider/AuthProvider";
import Loader from "../../utils/Loading";

export default function ProgramReminders({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const [loading, setLoading] = React.useState(true);
  // const [reminders, setReminders] = React.useState([]);
  const { userData, allReminders, setAllReminders } = useContext(AuthContext);
  const userService = Container.get(UserService);

  const handleDelete = async (item: any) => {
    setLoading(true);
    await userService.deleteAllNotificationPreferencesForProgram(
      userData?.id as string,
      item?.item?.program_id,
    );
    const [_reminders] = (
      await userService.getNotificationsPreferences(userData?.id as string)
    ).data;
    // setReminders(_reminders?.programs || []);
    if (setAllReminders) {
      setAllReminders(_reminders?.programs || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getNotificationsReminders = async () => {
      const [_reminders] = (
        await userService.getNotificationsPreferences(userData?.id as string)
      ).data;
      // setReminders(_reminders?.programs || []);
      if (setAllReminders) {
        setAllReminders(_reminders?.programs || []);
      }
    };
    getNotificationsReminders().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const getNotificationsReminders = async () => {
      const [_reminders] = (
        await userService.getNotificationsPreferences(userData?.id as string)
      ).data;
      // setReminders(_reminders?.programs || []);
      if (setAllReminders) {
        setAllReminders(_reminders?.programs || []);
      }
    };
    getNotificationsReminders().then(() => {
      setLoading(false);
    });
    setLoading(false);
  }, [allReminders?.length]);

  const handleGoBack = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.ACCOUNT_MAIN }],
    });
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[colors.buttonWhite, colors.buttonWhite]}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Header navigation={navigation} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <BackArrow handleGoBackCallback={handleGoBack} />
            <View style={{}}>
              <Text
                style={{
                  fontWeight: "700",
                  color: colors.blueSettings,
                  fontSize: 24,
                  marginBottom: 10,
                  alignSelf: "center",
                }}
              >
                PROGRAM REMINDERS
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  fontWeight: "500",
                  color: colors.blueSettings,
                  fontSize: 24,
                }}
              >
                Add New Program Reminder
              </Text>
              <Ionicons
                name="add-circle"
                size={30}
                color={colors.primaryColor}
                style={{
                  borderRadius: 5,
                  padding: 5,
                }}
                onPress={() => {
                  navigation.navigate(RouteNames.ADD_EDIT_PROGRAM_REMINDER, {
                    reminder: null,
                  });
                }}
              />
            </View>

            <FlatList
              data={allReminders?.length ? allReminders : []}
              renderItem={(item: any) => {
                return (
                  <View style={styles.itemContainer}>
                    <Text style={styles.itemText}>
                      {item?.item?.program_id?.toUpperCase()}
                    </Text>
                    <View style={styles.actionContainer}>
                      <View style={styles.actionIcon}>
                        <Ionicons
                          name="pencil-outline"
                          size={24}
                          onPress={() => {
                            navigation.navigate(
                              RouteNames.ADD_EDIT_PROGRAM_REMINDER,
                              {
                                reminder: item,
                              },
                            );
                          }}
                        />
                      </View>

                      <View style={styles.actionIcon}>
                        <Ionicons
                          name="close"
                          size={24}
                          onPress={() => handleDelete(item)}
                        />
                      </View>
                    </View>
                  </View>
                );
              }}
              keyExtractor={(item) => item.id}
              style={{ margin: 20 }}
            />
          </>
        )}
      </LinearGradient>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  itemText: {
    color: colors.blueSettings,
    fontSize: 24,
  },
  actionContainer: {
    flexDirection: "row",
  },
  actionIcon: {
    marginLeft: 10,
    borderRadius: 100,
    width: 44,
    height: 44,
    padding: 10,
    backgroundColor: colors.backGroundIcons,
    alignContent: "center",
    justifyContent: "center",
  },
});
