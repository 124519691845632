import { useEffect, useMemo, useState } from "react";
import { StyleSheet, Text, useWindowDimensions } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { colors } from "../../constants/colors";
import { IProgress } from "../../provider/AuthProvider";

type Props = {
  progress: IProgress;
  phyxId: string;
  isWeb?: boolean;
};

export default function ProgressChart({
  progress,
  phyxId,
  isWeb = true,
}: Props) {
  const [workoutData, setWorkoutData] = useState<any[]>([]);
  const [workoutList, setWorkoutList] = useState<any[]>([]);
  const { width, height } = useWindowDimensions();
  const [error, setError] = useState(null);

  const createDataArray = () => {
    const difficultyMap = {
      "0": "Just Right",
      "-1": "Challenging",
      "1": "Easy",
    };

    const _workoutData = [];
    const _workoutList = [];

    try {
      for (const [key, v] of Object.entries(progress)) {
        const workoutProgram = [];
        const tmp = [];
        const times = [];
        const difficulty = [];
        const session = [];
        const level = [];
        const customData = [];

        if (key === "customPhyx") {
          continue;
        }

        const value = v as any;

        for (let i = 0; i < value.length; i++) {
          if (!value[i]) continue;
          for (const [key2, value2] of Object.entries(value[i])) {
            for (let i2 = 0; i2 < value2.length; i2++) {
              if (value2[i2].VAS === null) {
                continue;
              }
              tmp.push(value2[i2].VAS);
              times.push(value2[i2].date);
              difficulty.push(
                value2[i2].difficulty !== undefined
                  ? difficultyMap[value2[i2].difficulty.toString()]
                  : "No Difficulty",
              );
              session.push(parseInt(key2) + 1);
              level.push(i);
              customData.push(false);
            }
          }
        }

        workoutProgram[0] = key;
        workoutProgram[1] = tmp;
        workoutProgram[2] = times;
        workoutProgram[3] = difficulty;
        workoutProgram[4] = session;
        workoutProgram[5] = level;
        workoutProgram[6] = customData;
        _workoutData.push(workoutProgram);
        _workoutList.push({ value: key, text: key });
      }

      if (_workoutList.length > 0) {
        // console.log(12345, workoutData);
        // if (workoutData[0].length > 0 && workoutData[0][2].length > 0)
        //   this.startingPnt = workoutData[0][2].reduce(function (a, b) {
        //     return a < b ? a : b;
        //   });
        // workoutData[0][7] = [this.startingPnt, 0, 0, 0];
      } else {
        _workoutList.push({ value: "No Data", text: "No Data" });
      }

      // let's add the smallest date to each
      for (const workoutArr of _workoutData) {
        const lowestDate =
          workoutArr[2].length > 0
            ? workoutArr[2].reduce(function (a, b) {
                return a < b ? a : b;
              })
            : new Date();
        workoutArr[7] = [lowestDate, 0, 0, 0];
      }

      console.log("ProgressChart WORKOUT", _workoutData);
    } catch (error: any) {
      setError(error);
      console.log("ProgressChart error: ", error);
    }
    setWorkoutData(_workoutData);
    setWorkoutList(_workoutList);
  };

  useEffect(() => {
    createDataArray();
  }, [progress]);

  const graphData = useMemo(() => {
    if (!workoutData.length || !phyxId) {
      return null;
    }

    const progressData = workoutData.find(item => item[0] === phyxId);

    if (!progressData || !progressData[2].length) {
      return null;
    }

    const labels = progressData[2].map((date: string) =>
      new Date(date).toLocaleDateString(),
    );
    const data = progressData[1];
    if (labels.length === 1) {
      labels.unshift("0");
      data.unshift(0);
    }

    return {
      labels,
      datasets: [
        {
          data,
        },
      ],
    };
  }, [workoutData, phyxId]);

  return graphData ? (
    <LineChart
      data={graphData}
      width={width * (isWeb ? 0.3 : 0.8)}
      height={height * (isWeb ? 0.5 : 0.2)}
      yAxisInterval={1}
      fromZero={true}
      chartConfig={{
        backgroundGradientFrom: "white",
        backgroundGradientTo: "white",
        decimalPlaces: 0,
        color: (opacity = 1) => colors.primaryColor,
        labelColor: (opacity = 1) => colors.secondaryColor,
        strokeWidth: 3,
        useShadowColorFromDataset: false,
        fillShadowGradient: colors.primaryColor,
        propsForBackgroundLines: (props: any) => colors.primaryColor,
      }}
      bezier
      style={{
        alignSelf: "flex-start",
      }}
    />
  ) : error ? (
    <Text>{error.message || error.toString()}</Text>
  ) : (
    <Text style={styles.emptyChart}>No data to display right now</Text>
  );
}

const styles = StyleSheet.create({
  emptyChart: {
    marginTop: 20,
    color: colors.primaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
});
