import { PRAC_PORTAL_URL_FOR_MESSAGES } from "@env";
import { Feather, Ionicons } from "@expo/vector-icons";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  KeyboardAvoidingView,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Avatar } from "react-native-paper";
import BackArrow from "../../components/Chat/backArrow";
import { handleFileDownload } from "../../webScreens/helper";

export default function Media({ navigation, route }: any) {
  const [chatFiles, setChatFiles] = useState([]);
  const scrollViewRef = useRef<any>();

  const { chatRoom } = route.params;

  if (!chatFiles) {
    navigation.goBack();
  }

  useEffect(() => {
    getChatFiles();
  }, []);

  const getChatFiles = async () => {
    try {
      const result = await axios.get(
        `${PRAC_PORTAL_URL_FOR_MESSAGES}/chat/files?chat_room_id=${chatRoom._id}`,
      );
      setChatFiles(result?.data);
      return result.data;
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  return (
    <SafeAreaView style={styles.container}>
      <BackArrow
        handleGoBackCallback={handleGoBack}
        title="Back to Conversation"
      />
      <View style={styles.chatHeader}>
        <View style={styles.rowLayout}>
          <View style={styles.profilePic}>
            <Avatar.Image
              source={require("../../../assets/images/logo.png")}
              size={20}
            />
          </View>
          {chatRoom.participants && chatRoom.participants.length > 2 && (
            <>
              {chatRoom.participants
                .slice(1)
                .map(
                  (
                    participant: { userProfile: { name: any } },
                    index: number,
                  ) =>
                    participant?.userProfile && (
                      <Text style={styles.text}>{`Shared With ${participant.userProfile.name
                        }${index < chatRoom.participants.length - 1 ? "," : " "
                        }`}</Text>
                    ),
                )}
            </>
          )}
          {!chatRoom.participants ||
            (chatRoom.participants.length <= 2 && chatRoom.participants[1] && (
              <Text style={styles.text}>{`Shared With ${chatRoom.participants[1]?.userProfile?.name ?? ""
                }`}</Text>
            ))}
        </View>
      </View>

      <KeyboardAvoidingView style={styles.files} behavior={"position"}>
        <ScrollView
          ref={scrollViewRef}
          onContentSizeChange={() =>
            scrollViewRef?.current?.scrollToEnd({ animated: true })
          }
        >
          {chatFiles &&
            chatFiles.reverse().map((file: any, index) => (
              <Fragment key={`file_${index}`}>
                <View style={styles.fileContainer}>
                  <Ionicons
                    name="newspaper-outline"
                    size={20}
                    color="rgba(24, 33, 77, 0.6)"
                  />
                  <View style={styles.fileName}>
                    <Text numberOfLines={1} style={styles.text}>
                      {file.name ?? "File"}
                    </Text>
                  </View>
                  <TouchableOpacity
                    style={styles.downloadIcon}
                    onPress={() => handleFileDownload(file)}
                  >
                    <Feather name="download" size={20} color="#10111B" />
                  </TouchableOpacity>
                </View>
              </Fragment>
            ))}
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#FFF",
  },
  chatHeader: {
    top: 0,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: 100,
    borderTopWidth: 1,
    borderTopColor: "rgba(108, 121, 147, 0.1)",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(108, 121, 147, 0.1)",
  },
  rowLayout: {
    flex: 2,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: 10,
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  files: {
    flex: 1,
    flexDirection: "column",
    margin: 10,
  },
  fileContainer: {
    flexDirection: "row",
    margin: 10,
    borderWidth: 1,
    borderColor: "rgba(24, 33, 77, 0.2)",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "space-between",
    padding: 15,
  },
  fileName: {
    width: "80%",
  },
  downloadIcon: {
    width: "10%",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "rgba(46, 57, 64, 0.6)",
    fontWeight: "600",
    fontSize: 12,
    fontFamily: "Proxima Nova",
  },
});
