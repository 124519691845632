import { Ionicons } from "@expo/vector-icons";
import React, { useContext } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import RenderImage from "../Common/renderImage";

export default function NewMessageModal({ navigation }: any) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { userData, modalData, setShowModals } = useContext(AuthContext) as any;
  if (!modalData) return;
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
      }}
    >
      <Ionicons
        name="close-outline"
        size={24}
        color="black"
        style={{ position: "absolute", top: 10, right: 10 }}
        onPress={() => {
          if (setShowModals) setShowModals(false);
        }}
      />
      <RenderImage
        uri={
          modalData.headerImage ||
          "https://phyxableprograms.s3.amazonaws.com/prevent.svg"
        }
        style={{ width: 200, height: 200 }}
      />
      <Text
        style={{
          fontSize: isTablet ? 20 : 10,
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: 10,
          fontFamily: "futura",
        }}
        numberOfLines={5}
      >
        {modalData.title}
      </Text>

      <View
        style={{
          width: isTablet ? "50%" : "100%",
          padding: 10,
          backgroundColor: colors.grey,
          borderRadius: 10,
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            fontSize: isTablet ? 20 : 12,
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: 10,
            fontFamily: "futura",
          }}
          numberOfLines={5}
        >
          {modalData.bodyTitle}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            modalData.button1Action();
            //@ts-ignore
            setShowModals(false);
          }}
          style={{
            backgroundColor: colors.blueColor,
            padding: isTablet ? 15 : 10,
            borderRadius: 100,
            marginTop: 10,
            minWidth: isTablet ? 150 : 100,
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: isTablet ? 20 : 12,
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            {modalData.button1}
          </Text>
        </TouchableOpacity>
        {modalData.button2 && <TouchableOpacity
          onPress={() => {
            modalData.button2Action();
            //@ts-ignore
            setShowModals(false);
          }}
          style={{
            backgroundColor: colors.blueColor,
            padding: isTablet ? 15 : 10,
            borderRadius: 100,
            marginTop: 10,
            minWidth: isTablet ? 150 : 100,
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: isTablet ? 20 : 12,
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            {modalData.button2}
          </Text>
        </TouchableOpacity>}
      </View>
    </View>
  );
}
