import { useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Notifications from "expo-notifications";
import { getDatabase, limitToLast, onChildAdded, ref } from "firebase/database";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Platform, useWindowDimensions } from "react-native";
import Container from "typedi";
import { UserService } from "../../services/user/user.service";
import { colors } from "../constants/colors";
import { RouteNames } from "../constants/routeNames";
import { AuthContext, ModalData } from "../provider/AuthProvider";
import { MainStackParamList } from "../types/navigation";
import DrawerStack from "./MainTabsDrawer";
import MainTabsMobile from "./MainTabsMobile";
// import * as Linking from "expo-linking";
import messaging from "@react-native-firebase/messaging";
import { GetFileNameFromURL } from "../webScreens/helper";

const MainStack = createNativeStackNavigator<MainStackParamList>();

export default function MainStackScreen() {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const stackToRender = isTablet ? DrawerStack : MainTabsMobile;
  const navigation = useNavigation();

  const {
    user,
    userData,
    setUserLocalData,
    showModals,
    setShowModals,
    setModalData,
  } = useContext(AuthContext);

  useEffect(() => {
    InitializeExternalService(
      userData?.id,
      user,
      setShowModals,
      showModals,
      setModalData,
      setUserLocalData,
      userData,
      navigation,
    );
    if (Platform.OS !== "web") {
      registerForPushNotifications(userData?.id as string);
    }
  }, [userData?.id]);

  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <MainStack.Screen name="MainTabs" component={stackToRender} />
    </MainStack.Navigator>
  );
}

export function InitializeExternalService(
  userId: any,
  user: any,
  setShowModals: any,
  showModals: boolean | undefined,
  setModalData: any,
  setUserData: any,
  userData: any,
  navigation: any,
) {
  const userService = Container.get(UserService);
  const realTimeDB = getDatabase();
  onChildAdded(
    (limitToLast(1), ref(realTimeDB, `users/${userId}/notifications`)),
    async (snapshot) => {
      if (snapshot.val() === null || user == null || showModals == true) {
        return;
      }
      const notification = snapshot.val();
      if (notification && notification?.data) {
        // Check if notification timestamp is within last 5 minutes
        const notificationTimestamp = moment(
          notification?.data?.timestamp,
        ).unix();
        const currentTimestamp = moment().unix();
        const difference = currentTimestamp - notificationTimestamp;
        if (difference > 3) {
          return;
        }
      }

      // Refresh notifications if user is logged in
      userService.getNotifications(userId).then((data: any) => {
        if (data.status === 200) {
          //@ts-ignore
          setUserData({
            ...userData,
            notifications: data.data,
          });
        }
      });

      let message;
      switch (true) {
        case notification?.body.includes("data:audio/*"):
          message = "Voice Message";
          break;
        case notification?.body.includes("phyxable-practitioner-portal"):
        case notification?.body.includes("firebasestorage.googleapis.com"):
          message = GetFileNameFromURL(notification?.body || "File");
          break;
        default:
          message = notification?.body;
          break;
      }

      // Show notification menu if user is logged in
      const currentRoute = navigation?.getCurrentRoute().name;
      if (
        currentRoute &&
        currentRoute.toLocaleLowerCase()?.includes("messages")
      ) {
        return;
      }
      const _notificationModalData: ModalData = {
        headerImage: "https://phyxableprograms.s3.amazonaws.com/prevent.svg",
        bodyImage: "",
        footerImage: "",
        title: notification.title,
        bodyTitle: message,
        bodyList: [],
        button1: "View",
        button2: "Close",
        type: "notification",
        button1Action: () => {
          const userServices = Container.get(UserService);
          userServices
            .markNotificationAsRead(userData.id, notification.data.id)
            .then(() => {
              setUserData({
                ...userData,
                notifications: userData.notifications.map(
                  (notification: any) =>
                    notification.id === notification.data.id
                      ? { ...notification, read: true }
                      : notification,
                ),
              });
              navigation.navigate(RouteNames.CHAT_MAIN, {
                screen: RouteNames.INBOX,
                params: {
                  loadChatRoomId: notification?.data?.chatRoomId || null,
                },
              });
            });
        },
        button2Action: () => {
          setShowModals(false);
        },
      };
      setModalData(_notificationModalData);
      setShowModals(true);
    },
    { onlyOnce: false },
  );
}

async function registerForPushNotifications(id: string) {
  const userService = Container.get(UserService);
  const { status } = await Notifications.getPermissionsAsync();
  let finalStatus = status;
  if (status !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== "granted") {
    alert("Failed to get push token for push notification!");
    return;
  }
  // const firebaseMessaging = require("@react-native-firebase/messaging");
  const token = await messaging().getToken();
  const deviceID = (await Notifications.getDevicePushTokenAsync()).data;
  try {
    await userService.addFCMToken(id, token, deviceID);
  } catch { }

  // Register for push notifications
  messaging().onMessage(async (remoteMessage) => {
    Notifications.scheduleNotificationAsync({
      content: {
        title: remoteMessage.notification?.title,
        body: remoteMessage.notification?.body,
        data: { data: remoteMessage.data },
      },
      trigger: null,
    });
  });
  // Background notification
  messaging().setBackgroundMessageHandler(async (remoteMessage) => {
    Notifications.scheduleNotificationAsync({
      content: {
        title: remoteMessage.notification?.title,
        body: remoteMessage.notification?.body,
        data: { data: remoteMessage.data },
      },
      trigger: null,
    });
  });
}
