import { Picker } from "@react-native-picker/picker";
import { CommonActions } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { Button, TextInput, useTheme } from "react-native-rapi-ui";
import Container from "typedi";
import { IChatMessage, UserService } from "../../../services/user/user.service";
import BackButton from "../../components/Common/BackButton";
import Header from "../../components/Common/HeaderComponent";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import Loader from "../utils/Loading";

export default function ComposeMessage({ navigation, route, data }: any) {
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const [loading, setLoading] = useState(false);

  const userService = Container.get(UserService);

  const { userData, setUserLocalData } = useContext(AuthContext);

  const [practitioner, setPractitioner] = useState<IPractitioner[]>();
  const [message, setMessage] = useState("");

  const handleSendMessage = async () => {
    setLoading(true);
    if (practitioner && practitioner.length > 0 && message.length > 0) {
      // Create the chatroom first then send the message to the chatroom
      var _res = await userService.createChatroom(
        userData?.id || "",
        practitioner[0].id,
      );
      if (_res.data) {
        const messagePayload: IChatMessage = {
          chat_room_id: _res.data._id,
          sender_id: userData?.id || "",
          sender_name: userData?.userProfile.name || "",
          data: message,
          messageType: "text",
          name: null,
          timestamp: new Date(),
          fileName: null,
          practitioner_id: practitioner[0].id,
        };
        await userService.sendMessageToChatroom(messagePayload);
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: RouteNames.INBOX }],
          }),
        );
      } else {
        alert("Please select practitioner and enter message");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    const getPractitioner = async () => {
      try {
        const res = await userService.getPatientsPractitioner(
          userData?.id as string,
        );

        if (res.data && res.data.length > 0) {
          const prac: IPractitioner[] = res.data.map((e: any) => ({
            fname: e.userProfile?.name || "",
            lname: e.userProfile?.lastName || "",
            id: e._id || "",
          }));

          return prac;
        }

        return [];
      } catch (error) {
        // Handle any errors here
      }
    };

    getPractitioner().then((prac) => {
      setPractitioner(prac);
      setLoading(false);
    });
  }, [userData?.id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={[colors.buttonWhite, colors.buttonWhite]}
          style={{ flex: 1 }}
        >
          <View>
            <Header navigation={navigation} />
            <BackButton navigation={navigation} />
            <View
              style={{
                marginTop: 20,
                width: isTablet ? "50%" : "100%",
                alignSelf: "center",
                padding: 10,
              }}
            >
              <Picker
                selectedValue={practitioner}
                onValueChange={(itemValue, itemIndex) =>
                  setPractitioner(itemValue)
                }
                style={{
                  height: 50,
                  color: colors.textColor,
                  marginBottom: 20,
                }}
              >
                {practitioner ? (
                  practitioner?.map((e) => {
                    return (
                      <Picker.Item label={e.fname + " " || e.id} value={e.id} />
                    );
                  })
                ) : (
                  <Picker.Item label="No Practitioner" value="" />
                )}
              </Picker>
              <TextInput
                placeholder="Message"
                multiline={true}
                numberOfLines={10}
                value={message}
                onChangeText={(val) => {
                  setMessage(val);
                }}
                style={{ marginTop: 20, color: colors.textColor }}
              />
              <Button
                color={colors.primaryColor}
                size="sm"
                width={isTablet ? 100 : 100}
                style={{ marginTop: 20, alignSelf: "center" }}
                text="Send"
                onPress={() => handleSendMessage()}
              />
            </View>
          </View>
        </LinearGradient>
      )}
    </>
  );
}

export interface IPractitioner {
  fname: string;
  lname: string;
  id: string;
}
