import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { RouteNames } from "../constants/routeNames";
import { Call } from "../screens";
import FeedbackPage from "../screens/call/Feedback";
import InitiateCall from "../screens/call/InitialCallScreen";
import JoinRoom from "../screens/call/JoinCallRoom";
import JoinGroupCall from "../screens/call/JoinGroupCall";

const callStack = createNativeStackNavigator();

export default function CallStack() {
  return (
    <callStack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackButtonMenuEnabled: true,
      }}
      initialRouteName={RouteNames.CALL_INITIATE}
    >
      <callStack.Screen
        name={RouteNames.VIDEO_CALL}
        component={Call}
        options={{
          headerShown: false,
        }}
      />
      <callStack.Screen
        name={RouteNames.CALL_INITIATE}
        component={InitiateCall}
        options={{
          headerShown: false,
        }}
      />
      <callStack.Screen
        name={RouteNames.CALL_JOIN}
        component={JoinGroupCall}
        options={{
          headerShown: false,
        }}
      />
      <callStack.Screen
        name={RouteNames.CALL_FEEDBACK}
        component={FeedbackPage}
        options={{
          headerShown: false,
        }}
      />
    </callStack.Navigator>
  );
}
