import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import ProgressCard from "../../components/Home/ProgressCard";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import {
  CustomWorkouts,
  IProgram,
  ProgramType,
} from "../../types/program.types";
import {
  getTodaysCustomSession,
  getTodaysSession,
} from "../../utils/userProfiles";
import CustomProgressChart from "../../webScreens/components/CustomProgressChart";
import ProgressChart from "../../webScreens/components/ProgressChart";

export default function Accomplishments({ navigation }: any) {
  const { userData } = useContext(AuthContext);
  const { width, height } = useWindowDimensions();

  const [level, setLevel] = useState(0);
  const [session, setSession] = useState(0);
  const [totalSessions, setTotalSessions] = useState<number>(0);
  const [completed, setCompleted] = useState(false);
  const [program, setProgram] = useState<IProgram | CustomWorkouts | null>(
    userData?.currentProgram as any,
  );

  useEffect(() => {
    if (userData?.currentProgram && userData?.userProfile.progress) {
      if (userData?.currentProgram?.type === ProgramType.Custom) {
        setProgram(userData?.currentProgram as CustomWorkouts);
        const _currentProgress = getTodaysCustomSession(
          userData?.userProfile,
          userData?.currentProgram as CustomWorkouts,
        );
        setCompleted(
          _currentProgress._totalSessions ===
            _currentProgress._sessionCompleted,
        );
        setLevel(_currentProgress._sessionCompleted);
        setSession(_currentProgress._todaySession);
        setTotalSessions(
          _currentProgress._totalSessions ||
            (program as CustomWorkouts)?.playlist?.length ||
            0,
        );
      } else {
        setProgram(userData?.currentProgram as IProgram);
        const _currentProgress = getTodaysSession(
          userData?.userProfile,
          userData?.currentProgram as IProgram,
        );
        setCompleted(!!_currentProgress.completed);
        setLevel(_currentProgress.level);
        setSession(_currentProgress.session + 1);
        setTotalSessions(
          _currentProgress.totalSessions ||
            (program as IProgram)?.phyxes?.length ||
            0,
        );
      }
    }
  }, [userData?.phyxes]);

  return (
    <ScrollView
      style={{
        flex: 1,
        height: "100%",
        width: width,
        backgroundColor: "white",
      }}
    >
      <View style={styles.container}>
        <Text style={styles.heading}>Accomplishments</Text>
        <Text style={styles.headingPara}>
          Your progress with selected program is listed below.
        </Text>
        {/* <ChipView /> */}
        {program ? (
          <Text style={styles.title}>
            {(program as IProgram).title ||
              (program as CustomWorkouts).program_name ||
              ""}
          </Text>
        ) : null}
        {/* Progress view */}
        {level !== null && session !== null && totalSessions !== null && (
          <ProgressCard
            level={level}
            session={session}
            totalSessions={totalSessions}
            customPhyx={
              (program as CustomWorkouts)?.type === ProgramType.Custom
                ? true
                : false
            }
          />
        )}
        <View
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
            marginBottom: 20,
          }}
        >
          <Text style={styles.heading}>Progress chart</Text>
          <Text style={styles.subHeading}>
            Pain scales{"\n"}
            <Text style={{ fontSize: 10 }}>
              ("0" for feel great, "10" for worst pain)
            </Text>
          </Text>

          {userData?.userProfile?.progress &&
            (program?.type === ProgramType.Custom ? (
              <CustomProgressChart
                progress={userData.userProfile.progress}
                customPhyxId={(program as CustomWorkouts)?._id}
                isWeb={false}
              />
            ) : program?.id_ ? (
              <ProgressChart
                progress={userData.userProfile.progress}
                phyxId={(program as IProgram).id_}
                isWeb={false}
              />
            ) : null)}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    paddingTop: 20,
  },
  title: {
    fontSize: 16,
    color: colors.primaryColor,
    fontWeight: "700",
    marginBottom: 2,
  },
  heading: {
    fontSize: 18,
    fontWeight: "800",
    color: colors.secondaryColor,
  },
  headingPara: {
    color: colors.secondaryColor,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
  },
  buttonContainer: {
    marginBottom: 20,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  listContainer: {
    marginTop: 10,
    width: "100%",
    marginBottom: 20,
  },
  list: {
    flexDirection: "row",
    alignItems: "center",
  },
  listText: {
    fontSize: 16,
    color: colors.secondaryColor,
  },
  chipContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  listTextBold: {
    fontWeight: "700",
  },
  // container: {
  //   // flex: 1,
  //   marginTop: 30,
  //   marginBottom: 50,
  // },
  // heading: {
  //   fontSize: 18,
  //   color: colors.secondaryColor,
  //   fontWeight: "700",
  // },
  subHeading: {
    marginTop: 2,
    color: colors.secondaryColor,
    fontWeight: "500",
    fontSize: 16,
  },
});

// export default copilot({
//   overlay: "svg", // or 'view'
//   animated: true, // or false
//   tooltipStyle: {
//     borderRadius: 5,
//   },
// })(Accomplishment);
