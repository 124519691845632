import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Avatar } from "react-native-paper";
import { Text, useTheme } from "react-native-rapi-ui";

import { LinearGradient } from "expo-linear-gradient";
import Container from "typedi";
import { ChatService } from "../../../services/chat/chat.service";
import { UserService } from "../../../services/user/user.service";
import BackArrow from "../../components/Chat/backArrow";
import Header from "../../components/Common/HeaderComponent";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext, NotificationData } from "../../provider/AuthProvider";
import { IRoom } from "../../types/chat";
import Loading from "../utils/Loading";

export default function Inbox({ navigation, route }: any) {
  const { isDarkmode, setTheme } = useTheme();

  const [me, setMe] = useState("");
  const [chatRooms, setChatRooms] = useState<IRoom[]>([]);
  const [loading, setLoading] = useState(true);
  const [chatRoomWithNewMessage, setChatRoomWithNewMessage] = useState<any>([]);

  const scrollViewRef = useRef<any>();

  const { userData, setUserLocalData } = useContext(AuthContext) as any;
  const { notifications = [] }: { notifications: NotificationData[] } =
    userData || {};

  useEffect(() => {
    const _chatRoomWithUnReadMessage = notifications?.filter(
      (item: any) => item?.data?.chatRoomId && item?.read === false,
    );
    if (_chatRoomWithUnReadMessage?.length > 0) {
      setChatRoomWithNewMessage(_chatRoomWithUnReadMessage);
    }
    getChatRooms().then((data) => {
      setChatRooms(data);
      setLoading(false);
    });
  }, [notifications]);

  useEffect(() => {
    const user = userData?.id;
    setMe(user);
  }, []);

  useEffect(() => {
    const user = userData?.id;
    setMe(user);
  }, [userData]);

  // Need to switch to me.id for Production deployment
  const patient_id = userData?.id;

  const getChatRooms = async () => {
    try {
      const chatService = Container.get(ChatService);
      const result = await chatService.getChatRooms({
        participant_id: patient_id,
      });
      let roomArr = [];
      for (let chatRoom of result?.data) {
        const { participants, last_message } = chatRoom;
        if (participants.some((p: any) => !p)) {
          continue;
        }
        let lastMessage = null;
        if (last_message) {
          const { type, ...others } = last_message;
          lastMessage = { ...others, messageType: type };
        }
        roomArr.push({
          _id: chatRoom.id,
          last_message: lastMessage,
          participants: participants,
        });
      }
      roomArr.sort((a, b) => {
        if (!a.last_message) {
          return 1;
        }
        if (!b.last_message) {
          return -1;
        }
        const timeA = new Date(a.last_message.timestamp).getTime();
        const timeB = new Date(b.last_message.timestamp).getTime();
        return timeB - timeA;
      });
      return roomArr;
    } catch (error) {
      console.error("error:", error);
      setChatRooms([]);
      return [];
    }
  };

  useEffect(() => {
    if (route.params?.loadChatRoomId) {
      const _chatRoom = chatRooms.find(
        (room: any) => room._id === route.params?.loadChatRoomId,
      );
      if (_chatRoom) {
        navigation.navigate(RouteNames.MESSAGES, {
          chatRoom: _chatRoom,
          patient_id,
        });
      }
    }
  }, [chatRooms, route.params?.loadChatRoomId, route.params?.newChatRoom]);

  const handleGoBackCallback = () => {
    navigation.goBack();
  };

  const handlePressRoom = (room: IRoom) => {
    // Find the notification with the chat room id and mark it as read
    const _notifications = notifications.filter(
      (item: any) => item?.data?.chatRoomId == room._id && item?.read === false,
    );
    if (_notifications && _notifications.length > 0) {
      const userServices = Container.get(UserService);
      _notifications.forEach(async (notification: any) => {
        await userServices
          .markNotificationAsRead(userData.id, notification.id)
          .then(() => {
            setUserLocalData({
              ...userData,
              notifications: notifications.map((item: any) =>
                item._id == notification._id
                  ? {
                      ...item,
                      read: true,
                    }
                  : item,
              ),
            });
          });
        // remove the chat room id from the chatRoomWithNewMessage
        setChatRoomWithNewMessage(
          chatRoomWithNewMessage.filter(
            (cRoom: any) => cRoom.data.chatRoomId != room._id,
          ),
        );

        navigation.navigate(RouteNames.CHAT_MAIN, {
          screen: RouteNames.INBOX,
          params: {
            loadChatRoomId: _notifications[0]?.data?.chatRoomId || null,
          },
        });
      });
    } else {
      navigation.navigate(RouteNames.MESSAGES, {
        chatRoom: room,
        patient_id,
      });
    }
  };

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[colors.buttonWhite, colors.buttonWhite]}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <SafeAreaView>
        <Header navigation={navigation} />
        <View style={{ height: "100%" }}>
          <BackArrow
            handleGoBackCallback={handleGoBackCallback}
            title={"Home"}
          />
          {loading ? (
            <Loading />
          ) : (
            <View style={styles.container}>
              <ScrollView
                ref={scrollViewRef}
                onContentSizeChange={() =>
                  scrollViewRef.current.scrollTo({
                    x: 0,
                    y: 0,
                    animated: true,
                  })
                }
              >
                {chatRooms?.length > 0 ? (
                  chatRooms.map((room: IRoom) => {
                    const { last_message } = room;
                    const practitioner = room.participants.find(
                      (participant) => participant.type === "practitioner",
                    );
                    const avatarURL = practitioner?.userProfile?.avatarURL;
                    return (
                      <Fragment key={room._id}>
                        <TouchableOpacity
                          style={styles.roomContainer}
                          onPress={() => {
                            handlePressRoom(room);
                          }}
                        >
                          <View style={styles.rowLayout}>
                            <View style={styles.profilePic}>
                              <Avatar.Image
                                source={
                                  avatarURL
                                    ? { uri: avatarURL }
                                    : require("../../../assets/images/logo.png")
                                }
                                size={60}
                              />
                            </View>
                            <View style={styles.roomColumn}>
                              {room.participants &&
                              room.participants.length > 2 ? (
                                room.participants.slice(1).map(
                                  (
                                    participant: {
                                      userProfile: { name: any };
                                    },
                                    index: number,
                                  ) => {
                                    return (
                                      participant?.userProfile && (
                                        <Text
                                          style={styles.roomParticipantText}
                                        >{`${participant?.userProfile?.name}${
                                          index < room.participants.length - 1
                                            ? ","
                                            : " "
                                        }`}</Text>
                                      )
                                    );
                                  },
                                )
                              ) : (
                                <Text
                                  style={styles.roomParticipantText}
                                >
                                  {room.participants[1]
                                    ? room.participants[1].userProfile?.name
                                    : ""}
                                </Text>
                              )}
                              <Text
                                numberOfLines={1}
                                style={styles.lastMessageText}
                              >
                                {last_message &&
                                last_message.messageType == "message"
                                  ? last_message.data
                                  : ""}
                              </Text>
                            </View>
                          </View>
                          {chatRoomWithNewMessage.filter(
                            (cRoom: any) => cRoom.data.chatRoomId == room._id,
                          )?.length > 0 && (
                            <TouchableOpacity
                              style={{
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                backgroundColor: colors.primaryColor,
                                width: 10,
                                height: 10,
                                borderRadius: 100,
                              }}
                            />
                          )}
                        </TouchableOpacity>
                      </Fragment>
                    );
                  })
                ) : (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: isDarkmode ? "#FFFFFF" : "#0C0D14",
                        fontWeight: "600",
                        fontSize: 14,
                        fontFamily: "Inter",
                      }}
                    >
                      No messages
                    </Text>
                  </View>
                )}
              </ScrollView>
            </View>
          )}
        </View>
      </SafeAreaView>
    </LinearGradient>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 10,
  },
  inboxHeader: {
    top: 0,
    width: "100%",
    paddingVertical: 25,
    paddingHorizontal: 10,
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "flex-start",
    borderTopWidth: 2,
    borderTopColor: "rgba(108, 121, 147, 0.1)",
    borderBottomWidth: 2,
    borderBottomColor: "rgba(108, 121, 147, 0.1)",
  },
  roomContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: "rgba(108, 121, 147, 0.1)",
    marginHorizontal: 10,
    padding: 12,
  },
  rowLayout: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  profilePic: {
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  roomColumn: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    padding: 3,
  },
  roomParticipantText: {
    fontWeight: "700",
    fontSize: 20,
    fontFamily: "Inter",
  },
  lastMessageText: {
    fontWeight: "800",
    fontSize: 14,
    fontFamily: "Inter",
    marginTop: 8,
  },
});
