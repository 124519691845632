import { CUSTOM_PHYX_BANNER_URI } from "@env";
import { AntDesign } from "@expo/vector-icons";
import React, { useContext, useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Card } from "react-native-paper";
import { Text, themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { CustomWorkouts } from "../../types/program.types";

export default function CustomPhyxCard({
  navigation,
  customPhyx,
}: {
  navigation: any;
  customPhyx: CustomWorkouts;
}) {
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const { customPrograms, setLocalCustomPrograms } = useContext(AuthContext);
  const [customPhyxExists, setCustomPhyxExists] = useState<boolean>(
    customPrograms?.some(
      (program: CustomWorkouts) => program._id === customPhyx._id,
    ) as boolean,
  );

  const handleItemPress = (program: any) => {
    customPhyxExists
      ? navigation.navigate(RouteNames.CUSTOMPHYX_OVERVIEW, {
          id: program?._id,
          image: CUSTOM_PHYX_BANNER_URI,
          data: program,
        })
      : navigation.navigate(RouteNames.CUSTOMPHYX_DETAIL, {
          param: {
            customPhyxId: program.id || program._id,
          },
        });
  };

  return (
    <Card
      style={[
        styles.container,
        {
          backgroundColor: isDarkmode ? themeColor.dark100 : themeColor.white,
          width: 200,
          marginRight: 10,
        },
      ]}
      onPress={() => handleItemPress(customPhyx)}
    >
      <Card.Cover
        source={{
          uri: CUSTOM_PHYX_BANNER_URI,
        }}
        style={styles.cardCover}
      />
      <Card.Title
        title={customPhyx.program_name}
        titleStyle={[
          styles.title,
          {
            color: isDarkmode ? themeColor.white : themeColor.primary900,
          },
        ]}
      />
      <Card.Content>
        <View style={styles.rowLayout}>
          <Text
            style={{
              fontSize: isTablet ? 14 : 12,
              color: colors.textColor,
              marginBottom: 5,
            }}
            numberOfLines={2}
          >
            {customPhyx.program_overview}
          </Text>
          <TouchableOpacity
            style={styles.button}
            onPress={() => handleItemPress(customPhyx)}
          >
            {customPhyxExists ? (
              <AntDesign
                name="playcircleo"
                size={isTablet ? 18 : 12}
                color="white"
              />
            ) : (
              <AntDesign name="plus" size={isTablet ? 18 : 12} color="white" />
            )}
          </TouchableOpacity>
        </View>
      </Card.Content>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    marginHorizontal: 5,
    marginBottom: 10,
  },
  // image: {
  //   width: "100%",
  //   borderTopLeftRadius: 15,
  //   borderTopRightRadius: 15,
  //   resizeMode: "cover",
  //   alignSelf: "center",
  // },
  // info: {
  //   paddingHorizontal: 10,
  //   flexDirection: "column",
  // },
  title: {
    fontWeight: "700",
    fontSize: 17,
    color: colors.secondaryColor,
    marginTop: 5,
  },
  // subTitle: {
  //   fontSize: 12,
  //   color: colors.textColor,
  //   marginBottom: 5,
  // },
  // overview: {
  //   fontSize: 12,
  //   color: colors.secondaryColor,
  //   flexShrink: 1,
  //   marginRight: 7,
  // },
  rowLayout: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    // bottom: 10,
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 10,
    width: "100%",
    alignSelf: "center",
  },
  cardCover: {
    height: 150,
  },
  // dailyCount: {
  //   fontSize: 12,
  //   color: colors.textColor,
  // },
  button: {
    borderRadius: 30,
    padding: 5,
    backgroundColor: colors.primaryColor,
  },
});
