import { Ionicons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LinearGradient } from "expo-linear-gradient";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Button, Text, TextInput, useTheme } from "react-native-rapi-ui";
import AuthHeader from "../../components/Auth/AuthHeader";
import { colors } from "../../constants/colors";
import { AuthStackParamList } from "../../types/navigation";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "ForgetPassword">) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();
  const auth = getAuth();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  async function forget() {
    setLoading(true);
    if (Platform.OS === "web") {
      try {
        await sendPasswordResetEmail(auth, email);
        navigation.navigate("Login");
        alert("Your password reset has been sent to your email");
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const firebaseAuth = require("@react-native-firebase/auth");
        const firebaseApp = require("@react-native-firebase/app");
        const auth = firebaseApp.default.auth();
        const res = await auth.sendPasswordResetEmail(email);
        if (res) {
          navigation.navigate("Login");
          alert("Your password reset has been sent to your email");
        }
      } catch (error) {
        alert(error);
        navigation.navigate("Login");
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={[colors.buttonWhite, colors.buttonWhite]}
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              width: isTablet ? 500 : "100%",
              alignSelf: "center",
            }}
          >
            <AuthHeader />

            <View
              style={{
                flex: 3,
                paddingHorizontal: 20,
                paddingBottom: 20,
              }}
            >
              <Text
                size="h3"
                fontWeight="bold"
                style={{
                  alignSelf: "center",
                  padding: 30,
                }}
              >
                Forgot Password
              </Text>
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Email address"
                value={email}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                keyboardType="email-address"
                onChangeText={(text) => setEmail(text)}
                leftContent={
                  <Ionicons
                    name="mail-outline"
                    size={20}
                    color={colors.primaryColor}
                  />
                }
              />
              <Button
                text={loading ? "Loading" : "Send email"}
                onPress={() => {
                  forget();
                }}
                style={{
                  marginTop: 20,
                }}
                disabled={loading}
                color={colors.primaryColor}
                size="md"
              />

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 15,
                  justifyContent: "center",
                }}
              >
                <Text size="md">Already have an account?</Text>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("Login");
                  }}
                >
                  <Text
                    size="md"
                    fontWeight="bold"
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    Login here
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 30,
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    isDarkmode ? setTheme("light") : setTheme("dark");
                  }}
                >
                  <Text
                    size="md"
                    fontWeight="bold"
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {/* {isDarkmode ? "☀️ light theme" : "🌑 dark theme"} */}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </LinearGradient>
      </>
    </KeyboardAvoidingView>
  );
}
