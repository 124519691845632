import * as WebBrowser from "expo-web-browser";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colors } from "../../constants/colors";
import Button from "../Home/button";

export default function BookAppointment({ user }: { user: any }) {
  const handleButtonClick = () => {
    WebBrowser.openBrowserAsync("https://phyxable.janeapp.com/");
  };

  return (
    <View style={styles.customPhyx}>
      <View>
        <Text style={styles.title}>
          <Text style={styles.name}>Hi, {user || ""}. Not sure what program to join?</Text>
        </Text>
        <Text style={styles.para}>
          Book a session with a licensed practitioner for a customized phyxable
          program to better help you relieve the pain!
        </Text>
        <Button
          text="BOOK SESSION"
          backgroundColor={colors.primaryColor}
          customStyle={{
            alignSelf: "center",
            marginTop: 10,
            paddingVertical: 5,
            marginBottom: 10,
          }}
          onPressCallback={handleButtonClick}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  customPhyx: {
    backgroundColor: "white",
    width: "80%",
    alignSelf: "center",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    borderRadius: 16,
    padding: 10,
    marginTop: 10,
  },
  title: {
    color: colors.secondaryColor,
    fontSize: 16,
    fontWeight: "700",
  },
  name: {
    color: colors.secondaryColor,
    fontSize: 16,
    fontWeight: "700",
  },
  para: {
    fontSize: 10,
    color: colors.textColor,
    marginTop: 5,
  },
});
