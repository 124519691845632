import { Feather } from "@expo/vector-icons";
import React from "react";
import { View } from "react-native";
import { colors } from "../../constants/colors";
import useSize from "../../hooks/useSize";


export default ({
  icon,
  focused,
  topRightIcon,
}: {
  icon: any;
  focused: boolean;
  topRightIcon: any;
}) => {
  const { isMobile, isTablet } = useSize();

  return topRightIcon ? (
    <View>
      <View
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          opacity: 0.8,
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: colors.primaryColor,
        }}
      ></View>
      <Feather
        name={icon}
        style={{}}
        size={isMobile || isTablet ? 20 : 32}
        color={focused ? colors.primaryColor : colors.textColor}
      />
    </View>
  ) : (
    <Feather
      name={icon}
      style={{}}
      size={isMobile || isTablet ? 20 : 32}
      color={focused ? colors.primaryColor : colors.textColor}
    />
  );
};
