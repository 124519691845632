import { LOGO_URI, PHYXABLE_PLUS } from "@env";
import { MaterialIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Modal, Portal } from "react-native-paper";
import { Button } from "react-native-rapi-ui";
import { SvgCssUri } from "react-native-svg";
import { colors } from "../../constants/colors";

type Props = {
  show: boolean;
  handlePopupClick: () => void;
  handlePopUpClose: () => void;
};

const PhyxablePlus = ({ show, handlePopupClick, handlePopUpClose }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  const handleButtonClick = () => {
    handlePopupClick();
  };

  const handleClose = () => {
    handlePopUpClose();
  };

  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={() => setVisible(false)}
        contentContainerStyle={styles.container}
      >
        <TouchableOpacity style={styles.close} onPress={handleClose}>
          <MaterialIcons name="close" size={24} color="black" />
        </TouchableOpacity>
        <SvgCssUri
          width={150}
          uri={LOGO_URI}
          style={{
            alignSelf: "center",
            // resizeMode: "contain",
          }}
        />
        <View style={styles.content}>
          <Text style={styles.heading}>
            Unlock<Text style={styles.boldHeading}> Phyxable Plus</Text>
          </Text>
          <View style={styles.listContainer}>
            <View style={styles.list}>
              <MaterialIcons
                name="check-circle"
                size={24}
                color={colors.green}
              />
              <Text style={styles.listText}>
                Access to all self help solutions
              </Text>
            </View>
            <View style={styles.list}>
              <MaterialIcons
                name="check-circle"
                size={24}
                color={colors.green}
              />
              <Text style={styles.listText}>Over 100+ guided sessions</Text>
            </View>
            <View style={styles.list}>
              <MaterialIcons
                name="check-circle"
                size={24}
                color={colors.green}
              />
              <Text style={styles.listText}>
                Access to 1-on-1 virtual rehab{"\n"}with a licensed practitioner
              </Text>
            </View>
            <View style={styles.list}>
              <MaterialIcons
                name="check-circle"
                size={24}
                color={colors.green}
              />
              <Text style={styles.listText}>
                Track your progress and{"\n"}earn rewards
              </Text>
            </View>
            <View style={styles.list}>
              <MaterialIcons
                name="check-circle"
                size={24}
                color={colors.green}
              />
              <Text style={styles.listText}>
                Daily prevention solutions{"\n"}to stay pain free
              </Text>
            </View>
          </View>
          <ImageBackground
            source={{ uri: PHYXABLE_PLUS }}
            style={styles.illustration}
            resizeMode="cover"
            imageStyle={{
              borderBottomRightRadius: 16,
              borderBottomLeftRadius: 16,
            }}
          >
            <Button
              text="VIEW ALL PLANS"
              textStyle={{ fontWeight: "100" }}
              onPress={handleButtonClick}
              style={{
                alignSelf: "center",
                marginBottom: 15,
                borderRadius: 20
              }}
              width='75%'
              color={colors.secondaryColor}
              size="md"
            />
          </ImageBackground>
        </View>
      </Modal>
    </Portal>
  );
};

export default PhyxablePlus;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    marginHorizontal: 15,
    borderRadius: 16,
  },
  close: {
    alignItems: "flex-end",
    marginRight: 10,
    marginTop: 10,
  },
  image: {
    width: 200,
    height: 100,
  },
  content: {
    alignItems: "center",
  },
  heading: {
    color: colors.secondaryColor,
    fontSize: 24,
  },
  boldHeading: {
    fontWeight: "700",
  },
  listContainer: {
    marginTop: 10,
    alignItems: "flex-start",
    paddingHorizontal: 20,
  },
  list: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 15,
    overflow: "hidden",
  },
  listText: {
    color: colors.secondaryColor,
    fontSize: 17,
    marginLeft: 10,
  },
  illustration: {
    width: "100%",
    height: 200,
    justifyContent: "flex-end",
  },
});
