import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Text } from "react-native-paper";
import Container from "typedi";
import { PaymentSubscriptionService } from "../../../services/paymentSubscription/paymentSubscription";
import CustomSwitch from "../../components/Account/payment/CustomSwitch";
import BackArrow from "../../components/Chat/backArrow";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { getSubscriptions } from "../../webScreens/helper";
import Loader from "../utils/Loading";
import ActiveSubscription from "./ActiveSubscriptionPage";

export default function Subscription({ navigation }: { navigation: any }) {
  const [duration, setDuration] = useState(1);
  const [practitionerExpand, setPractitionerExpand] = useState(false);
  const [selfExpand, setSelfExpand] = useState(false);
  const [isPromoCode, setIsPromoCode] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(AuthContext);
  const onSelectSwitch = (index: any) => {
    if (index === 1) {
      setPlansInView(monthlySubscriptions);
      setPeriod("month");
    } else {
      setPlansInView(yearlySubscriptions);
      setPeriod("year");
    }
  };
  const [monthlySubscriptions, setMonthlySubscriptions] = useState([]);
  const [yearlySubscriptions, setYearlySubscriptions] = useState([]);
  const [plansInView, setPlansInView] = useState<any>([]);
  const [period, setPeriod] = useState("month");
  const [registeredCompany, setRegisteredCompany] = useState("");
  const [allCompanies, setAllCompanies] = useState<any>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any>(null);
  const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
  const [activeSubscription, setActiveSubscription] = useState<any>(null);

  const paymentService = Container.get(PaymentSubscriptionService);

  useEffect(() => {
    getSubscriptions(
      userData,
      paymentService,
      setMonthlySubscriptions,
      setYearlySubscriptions,
      setPlansInView,
      setActiveSubscription,
      setAllCompanies,
    );
  }, []);

  const handleGoBack = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.ACCOUNT_MAIN }],
    });
  };

  const handleSelectPlan = (plan: any) => {
    navigation.navigate(RouteNames.SUBSCRIPTION_CONFIRMATION, {
      subscription: plan,
      period: period,
      company: registeredCompany,
      coupon: selectedCoupon,
    });
  };

  const handleUpgrade = () => {
    setActiveSubscription(null);
  };

  return (
    <SafeAreaView style={{ flexGrow: 1 }}>
      {loading ? (
        <Loader />
      ) : (
        <LinearGradient
          style={styles.container}
          locations={[0, 1]}
          colors={[colors.buttonWhite, colors.buttonWhite]}
        >
          {activeSubscription ? (
            <View
              style={{
                marginHorizontal: "auto",
                width: "100%",
              }}
            >
              <BackArrow
                handleGoBackCallback={handleGoBack}
                title={"Account"}
              />
              <ActiveSubscription
                subscription={activeSubscription}
                period={period}
                handleUpgrade={handleUpgrade}
              />
            </View>
          ) : (
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  paddingHorizontal: 20,
                  width: '100%'
                }}
              >
                <BackArrow
                  handleGoBackCallback={handleGoBack}
                  title={"Account"}
                />
                {/* Heading */}
                <View style={styles.heading}>
                  <Text style={styles.headingText}>
                    Phyxable Plus Experience
                  </Text>
                </View>

                {/* sub-heading */}
                <View style={styles.subHeading}>
                  <Text style={styles.subHeadingText}>
                    Select a plan to see what it offers.
                  </Text>
                </View>

                {/* add toggle button here */}
                <View style={styles.switch}>
                  <CustomSwitch
                    selectionMode={duration}
                    roundCorner={true}
                    option1={"MONTHLY"}
                    option2={"ANNUALLY"}
                    onSelectSwitch={onSelectSwitch}
                    selectionColor={"#18214D"}
                    navigation={undefined}
                  />
                </View>

                {/* Practitioner Guided */}
                <View style={styles.practitionerGuided}>
                  <TouchableOpacity
                    onPress={() => setPractitionerExpand(!practitionerExpand)}
                  >
                    <View
                      style={
                        practitionerExpand
                          ? {
                              ...styles.planCardHighlight,
                              flexDirection: "row",
                            }
                          : { ...styles.planCard, flexDirection: "row" }
                      }
                    >
                      <View>
                        <Text
                          style={
                            practitionerExpand
                              ? styles.textPlanHighlight
                              : styles.textPlan
                          }
                        >
                          Practitioner Guided{" "}
                        </Text>
                        <Text
                          style={
                            practitionerExpand
                              ? styles.priceHighlight
                              : styles.price
                          }
                        >
                          $
                          {
                            plansInView.plans?.filter((item: any) =>
                              item.type.toLowerCase().includes("practitioner"),
                            )[0]?.price
                          }{" "}
                          / {period}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        <Text style={styles.bestValue}>BEST VALUE</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>

                <View
                  style={
                    practitionerExpand
                      ? { ...styles.cardExpand, display: "flex" }
                      : { ...styles.cardExpand, display: "none" }
                  }
                >
                  <FlatList
                    data={
                      plansInView.plans?.filter((item: any) =>
                        item.type.toLowerCase().includes("practitioner"),
                      )[0]?.points
                    }
                    renderItem={({ item }) => (
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          paddingBottom: 16,
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank-circle"
                          size={15}
                          color="#3EA65C"
                          style={{ marginVertical: "auto", marginLeft: 5 }}
                        />
                        <Text style={{ marginLeft: 5, marginRight: 25 }}>
                          {item}
                        </Text>
                      </View>
                    )}
                  />
                  <View>
                    <TouchableOpacity
                      style={[styles.button, { width: 150 }]}
                      onPress={() =>
                        handleSelectPlan(
                          plansInView.plans?.filter((item: any) =>
                            item.type.toLowerCase().includes("practitioner"),
                          )[0],
                        )
                      }
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontWeight: "600",
                        }}
                      >
                        Subscribe
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                {/* Self Guided */}
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    marginTop: 10,
                  }}
                >
                  <TouchableOpacity onPress={() => setSelfExpand(!selfExpand)}>
                    <View
                      style={
                        selfExpand ? styles.planCardHighlight : styles.planCard
                      }
                    >
                      <Text
                        style={
                          selfExpand
                            ? styles.textPlanHighlight
                            : styles.textPlan
                        }
                      >
                        Self Guided
                      </Text>
                      <Text
                        style={
                          selfExpand ? styles.priceHighlight : styles.price
                        }
                      >
                        $
                        {
                          plansInView.plans?.filter((item: any) =>
                            item.type.toLowerCase().includes("self"),
                          )[0]?.price
                        }{" "}
                        / {period}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View
                  style={
                    selfExpand
                      ? { ...styles.cardExpand, display: "flex" }
                      : { ...styles.cardExpand, display: "none" }
                  }
                >
                  <FlatList
                    data={
                      plansInView.plans?.filter((item: any) =>
                        item.type.toLowerCase().includes("self"),
                      )[0]?.points
                    }
                    renderItem={({ item }) => (
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          paddingBottom: 16,
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank-circle"
                          size={15}
                          color="#3EA65C"
                          style={{ marginVertical: "auto", marginLeft: 5 }}
                        />
                        <Text style={{ marginLeft: 5, marginRight: 25 }}>
                          {item}
                        </Text>
                      </View>
                    )}
                  />
                  <View>
                    <TouchableOpacity
                      style={[styles.button, { width: 150 }]}
                      onPress={() =>
                        handleSelectPlan(
                          plansInView.plans?.filter((item: any) =>
                            item.type.toLowerCase().includes("self"),
                          )[0],
                        )
                      }
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontWeight: "600",
                        }}
                      >
                        Subscribe
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                {/* Have a promo code? */}
                <View
                  style={{
                    display: "flex",
                    marginHorizontal: "auto",
                    marginTop: 15,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setIsPromoCode(!isPromoCode)}
                  >
                    <Text
                      style={[
                        styles.textPlan,
                        { textDecorationLine: "underline" },
                      ]}
                    >
                      Have a promo code?
                    </Text>
                  </TouchableOpacity>
                </View>

                {/* Promo code */}
                <View
                  style={
                    isPromoCode
                      ? { display: "flex", marginTop: 15 }
                      : { display: "none" }
                  }
                >
                  <TextInput
                    placeholder="Enter Promo code"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 16,
                      marginHorizontal: "auto",
                      width: "100%",
                      paddingVertical: 12,
                      paddingLeft: 16,
                      paddingRight: 86,
                      height: 48,
                    }}
                    value={selectedCoupon || ""}
                    onChangeText={(val) => setSelectedCoupon(val)}
                  ></TextInput>
                  <View style={{ marginBottom: 16 }}>
                    <TouchableOpacity
                      style={{
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                        backgroundColor: "#18214D",
                        borderRadius: 10,
                        width: 67,
                        height: 32,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 12,
                          fontWeight: "600",
                          alignContent: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                          padding: 5,
                        }}
                      >
                        APPLY
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                {/* <Text
              style={{
                fontWeight: "600",
                fontSize: 16,
                color: "#41455D",
                marginTop: 2,
                marginHorizontal: "auto",
                paddingLeft: 6,
                paddingTop: 15,
              }}
            >
              Is your company registered with Phyxable?
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "flex-start",
                marginLeft: 32,
                marginTop: 16,
                justifyContent: "space-evenly",
              }}
            >
              <RadioButton
                value={isRegistered === (true as any)}
                onValueChange={(val) => setIsRegistered(true)}
              />
              <Text style={{ marginLeft: 10, color: "gray" }}>Yes</Text>
              <RadioButton
                value={isRegistered === (false as any)}
                onValueChange={(val) => setIsRegistered(false)}
                style={{ marginLeft: 20 }}
              />
              <Text style={{ marginLeft: 10, color: "gray" }}>No</Text>
            </View>
            <View
              style={
                isRegistered
                  ? { display: "flex", marginTop: 15 }
                  : { display: "none" }
              }
            >
              <Searchbar
                placeholder="Search by company name"
                onChangeText={(_string) => {
                  setFilteredCompanies(_string);
                }}
                value={filteredCompanies || registeredCompany}
              />
              <FlatList
                data={
                  !filteredCompanies
                    ? null
                    : allCompanies.filter((item: any) =>
                        item.code
                          .toLowerCase()
                          .includes(filteredCompanies.toLowerCase())
                      )
                }
                renderItem={({ item }) => (
                  <List.Item
                    title={item.code}
                    onPress={() => {
                      setFilteredCompanies(null);
                      setRegisteredCompany(item.code);
                    }}
                  />
                )}
              />
            </View> */}
              </View>
            </ScrollView>
          )}
        </LinearGradient>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    flex: 1,
    flexDirection: "row",
  },

  heading: {
    marginHorizontal: "auto",
    height: 58,
    marginTop: 30,
  },

  headingText: {
    marginHorizontal: "auto",
    fontSize: 24,
    fontWeight: "700",
    color: "#18214D",
    width: "80%",
    textAlign: "center",
  },

  subHeading: {
    marginHorizontal: "auto",
    marginTop: 24,
    marginBottom: 24,
  },

  subHeadingText: {
    color: "#18214D",
    fontWeight: "400",
    fontSize: 16,
  },

  switch: {
    marginHorizontal: "auto",
    marginBottom: 24,
  },

  practitionerGuided: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: "100%",
    display: "flex",
  },

  bestValue: {
    backgroundColor: "#FCA727",
    color: "#FFFFFF",
    width: 73,
    height: 24,
    fontSize: 8,
    fontWeight: "700",
    textAlign: "center",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    padding: 5,
  },

  price: {
    fontWeight: "700",
    fontSize: 20,
    color: "#18214D",
    flexDirection: "row",
  },

  priceHighlight: {
    fontWeight: "700",
    fontSize: 20,
    color: "#fff",
    flexDirection: "row",
  },

  textPlan: {
    color: "#18214D",
    fontWeight: "400",
    fontSize: 16,
    flexDirection: "row",
  },

  textPlanHighlight: {
    color: "#fff",
    fontWeight: "400",
    fontSize: 16,
    flexDirection: "row",
  },

  planCard: {
    padding: 8,
    backgroundColor: "#fff",
    borderRadius: 8,
  },

  planCardHighlight: {
    padding: 8,
    backgroundColor: "#18214D",
    borderRadius: 8,
  },

  cardExpand: {
    backgroundColor: "#fff",
    borderRadius: 8,
    paddingTop: 24,
    paddingLeft: 8,
    paddingRight: 8,
    maxWidth: "100%",
  },

  button: {
    borderRadius: 24,
    backgroundColor: colors.primaryColor,
    alignSelf: "center",
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingVertical: 10,
    marginBottom: 20,
  },

  buttonLabel: {
    backgroundColor: colors.orange,
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingVertical: 10,
    marginBottom: 20,
    position: "absolute",
    right: 89,
    zIndex: 1,
    width: 150,
    height: 42,
    top: "-3%",
  },
});
