import { LinearGradient } from 'expo-linear-gradient';
import React, { useContext } from 'react';
import { FlatList, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { videoChatService } from '../../../services/videochat/videochat.service';
import BackArrow from '../../components/Chat/backArrow';
import Header from '../../components/Common/HeaderComponent';
import { colors } from '../../constants/colors';
import { RouteNames } from '../../constants/routeNames';
import { AuthContext } from '../../provider/AuthProvider';
import Loader from '../utils/Loading';

export default function JoinGroupCall({ navigation }: any) {
    const { width, height } = useWindowDimensions();
    const isTablet = width >= colors.tabletSize;
    const { userData, showNotificationMenu } = useContext(AuthContext);
    const [loading, setLoading] = React.useState(true);
    const [groupMeetings, setGroupMeetings] = React.useState<group_meetings[]>([]);


    const handleGoBack = () => {
        navigation.navigate(RouteNames.CALL_INITIATE);
    };

    React.useEffect(() => {
        const getGroupMeetings = async () => {
            try {
                const response = await videoChatService.getGroupMeetings();
                setGroupMeetings(response.data);
                setLoading(false);
            } catch (error) {
                console.log("error", error);

            }
        };
        getGroupMeetings();
    }, []);

    const handleJoinCall = (meetingId: string) => {
        const meetingLink = groupMeetings.find((meeting) => meeting.meetingId === meetingId)?.viewerRoomUrl;
        navigation.navigate(RouteNames.VIDEO_CALL, {
            roomName: userData?.email,
            userName: userData?.id,
            meetingLink: meetingLink,
            meetingId: meetingId,
        });
    }

    return (
        <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={[colors.buttonWhite, colors.buttonWhite]}
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <SafeAreaView>
                <Header navigation={navigation} />
                <ScrollView
                    contentContainerStyle={{
                        flexGrow: 1,
                        height: "80%"
                    }}
                >
                    {isTablet && (
                        <BackArrow handleGoBackCallback={handleGoBack} title={"Call"} />
                    )}
                    <Card
                        style={{
                            marginTop: isTablet ? 20 : 0,
                            width: isTablet ? "80%" : "100%",
                            alignSelf: "center",
                            zIndex: showNotificationMenu ? -1 : 0,
                            flex: 1,
                            marginBottom: isTablet ? 8 : 0,
                            borderRadius: isTablet ? 10 : 0,
                            backgroundColor: "#f5f5f5",
                            padding: 10,
                            height: "100%",
                        }}
                        contentStyle={{ height: "100%" }}
                    >
                        <Card.Title
                            title="Join Group Call"
                            titleStyle={{ fontSize: 25, fontWeight: "bold", textAlign: "center", color: colors.blueColor }}
                        />

                        {loading ? <Loader /> :
                            <View style={styles.container}>
                                <View style={styles.listHeader}>
                                    <Text style={styles.columnHeader}>Session</Text>
                                    <Text style={styles.columnHeader}>Join Call</Text>
                                </View>
                                <FlatList
                                    data={groupMeetings}
                                    keyExtractor={(item) => item.id as string}
                                    renderItem={({ item }) => (
                                        <View style={styles.sessionItem}>
                                            <View style={styles.sessionInfo}>
                                                <Text style={styles.sessionTitle}>{item.roomName}</Text>
                                                <Text style={styles.sessionDate}>For {new Date(item.dateTime as string).toDateString()}</Text>
                                            </View>
                                            <View style={styles.actions}>
                                                <TouchableOpacity style={styles.joinButton} onPress={() => handleJoinCall(item.meetingId as string)}>
                                                    <Text style={styles.joinText}>Join</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    )}
                                />
                            </View>
                        }
                    </Card>
                </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    );

}


const styles = StyleSheet.create({
    container: {
        padding: 16,
        backgroundColor: '#f5f5f5',
        borderRadius: 12,
        width: '90%',
        alignSelf: 'center',
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    listHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    columnHeader: {
        fontWeight: '600',
        color: colors.primaryColor,
        fontSize: 18,
    },
    sessionItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    sessionInfo: {
        flex: 1,
    },
    sessionTitle: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#333',
    },
    sessionDate: {
        fontSize: 14,
        color: '#666',
    },
    actions: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    joinButton: {
        backgroundColor: colors.primaryColor,
        paddingVertical: 6,
        paddingHorizontal: 12,
        borderRadius: 6,
        marginRight: 10,
    },
    joinText: {
        color: '#fff',
        fontWeight: '600',
        fontSize: 16,
    },
    closeButton: {
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    closeText: {
        fontSize: 18,
        color: '#666',
    },
});


export interface group_meetings {
    id: String
    practitioner_id: String
    dateTime: String
    roomName: String
    hostRoomUrl: String
    meetingId: String
    viewerRoomUrl: String
}