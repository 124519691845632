import React, { useEffect } from 'react';
import { View } from 'react-native';


export default function WhereByCallWeb({ roomUrl, onLeaveHandler }: { roomUrl: string, onLeaveHandler: any }) {

  useEffect(() => {
    const elm = document.querySelector("whereby-embed");
    const leaveCall = () => {
      if (elm) {
        onLeaveHandler();
      }
    };
    elm?.addEventListener("leave", leaveCall);
    return () => {
      elm?.removeEventListener("leave", leaveCall);
    };
  }, []);

  return (
    <View>
      <whereby-embed room={roomUrl} style={{ width: "100%", height: "100vh" }}></whereby-embed>
    </View>
  );
}
