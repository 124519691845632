import React from "react";
import VideoCall from "../../components/VideoCall/VideoCall";

export default function Call({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  return <VideoCall navigation={navigation} route={route} />;
}
