import { MaterialIcons } from "@expo/vector-icons";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Modal,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Overlay } from "react-native-elements";
import { Divider, List } from "react-native-paper";
import ReadMore from "react-native-read-more-text";
import { PendoSDK } from "rn-pendo-sdk";
import Button from "../../components/Home/button";
import OfflineModal from "../../components/Program/OfflineModal";
import { ProgramBanner } from "../../components/Program/ProgramBanner";
import EquipmentItem from "../../components/Program/equipmentItem";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import {
  AuthContext,
  AuthContextProps,
  VideoCache,
} from "../../provider/AuthProvider";
import {
  IMp4,
  IPhyx,
  IProgram,
  IVideo,
  VideoIntro,
  VideoReps,
  VideoTimer,
} from "../../types/program.types";
import { IEquipment } from "../../types/types";
import { clearCache, downloadVideo } from "../../utils/cache";
import { getTodaysSession, isPhyxablePlus } from "../../utils/userProfiles";
import Loading from "../utils/Loading";
import PhyxablePlus from "./PhyxablePlus";

const toggleOn = require("../../webScreens/assets/toggle-on.png");
const toggleOff = require("../../webScreens/assets/toggle-off.png");

async function downloadMedias(cache: VideoCache, mediaLinks: string[]) {
  const newCache: VideoCache = { ...cache };
  const result = await Promise.all(
    mediaLinks.map(async (link) => {
      if (link && !newCache[link]) {
        try {
          const data = (await axios.get(link)).data as IVideo;
          return { link, data };
        } catch (err) {}
      }
      return { link, data: null };
    }),
  );
  result.forEach(({ link, data }) => {
    if (data) {
      newCache[link] = data;
    }
  });
  if (Object.keys(newCache).length === 0) {
    return newCache;
  }
  try {
    const links = collectLinks(newCache, mediaLinks);
    await Promise.all(links.map((link) => downloadVideo(link)));
  } catch {}
  return newCache;
}

function collectLinks(cache: VideoCache, links: string[]) {
  const linkSet = new Set<string>();
  for (let i = 0; i < links.length; i += 1) {
    const link = links[i];
    const data = cache[link];
    const { playlist } = data;
    const videoToUse = playlist[0]?.sources?.filter(
      (video) => video.file && video.file !== "" && video.type === "video/mp4",
    ) as IMp4[];
    videoToUse.sort((a, b) => {
      if (a.height < b.height) {
        return 1;
      } else if (a.height > b.height) {
        return -1;
      } else {
        return 0;
      }
    });
    linkSet.add(playlist[0].image);
    const video =
      videoToUse && videoToUse.length > 0
        ? videoToUse[0].file
        : (playlist || [])[0].sources[i + 1 || 0].file;
    linkSet.add(video);
  }
  return Array.from(linkSet);
}

interface Params {
  id: string;
  image: string;
  data: IProgram;
}

export default function ProgramOverView({ navigation, route }: any) {
  const { id, image, data: programData } = route.params as Params;
  const [equipment, setEquipment] = useState<IEquipment[]>();
  const [level, setLevel] = useState(1);
  const [session, setSession] = useState(0);
  const [totalSessions, setTotalSession] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUserPaid, setIsUserPaid] = useState<boolean>(false);
  const [isOfflineAccess, setIsOfflineAccess] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { width } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { userData, setUserLocalData, videoCache, setLocalVideoCache } =
    useContext(AuthContext) as AuthContextProps;
  const isMobile = Platform.OS !== "web";

  useEffect(() => {
    var result = getTodaysSession(userData?.userProfile, programData);
    setLevel(result.level);
    setSession(result.session);
    setTotalSession(result.totalSessions);
    setIsCompleted(result.completed ?? false);
    setSubscription();
  }, []);

  useEffect(() => {
    setIsOfflineAccess(userData?.offlineProgram === programData?.id_);
  }, [userData?.offlineProgram, programData?.id_]);

  useEffect(() => {
    if (!programData || !programData.equipment) {
      return;
    }
    let equipment = [];
    for (let i = 0; i < programData.equipment.length; i++) {
      equipment.push({
        image: programData.equipment_image[i],
        name: programData.equipment[i],
      });
    }
    setEquipment(equipment);
  }, [programData]);

  const setSubscription = async () => {
    if (isPhyxablePlus(userData?.userProfile?.subscription)) {
      setIsUserPaid(true);
    } else {
      setIsUserPaid(false);
    }
  };

  const handleContinue = () => {
    if (shouldAllowUserToProceed()) {
      navigation.navigate(RouteNames.STOP_INTERSTITIAL, {
        id: id,
        image: image,
        interstitial: getInterstitial(),
        vas: getVAS(),
        psfs: getPSFS(),
        level,
        session,
        data: programData,
      });
      PendoSDK.track("Start Session", { title: programData?.title });
    } else {
      setShowPopup(true);
    }
  };

  const getMediaLinks = useCallback((phyx: IPhyx) => {
    const [introVideo] = phyx.pages.filter(
      ({ type }) => type === "videoIntro",
    ) as VideoIntro[];
    const links: string[] = [];
    if (introVideo?.videoUrl) {
      links.push(introVideo.videoUrl);
    }
    const videos = phyx.pages.filter(
      (page) => (page as VideoReps).session_title,
    ) as (VideoTimer | VideoReps)[];
    videos.forEach((video) => video.videoUrl && links.push(video.videoUrl));
    return links;
  }, []);

  const handleDownload = async () => {
    setIsDownloading(true);
    await clearCache();
    const mediaLinkSet = new Set<string>();
    getMediaLinks(programData.phyxes[level][session]).forEach((link) =>
      mediaLinkSet.add(link),
    );
    for (const _level in programData.phyxes) {
      for (const phyx of programData.phyxes[_level]) {
        getMediaLinks(phyx).forEach((link) => mediaLinkSet.add(link));
      }
    }

    const mediaLinks = Array.from(mediaLinkSet);
    try {
      const cache = await downloadMedias(videoCache, mediaLinks);
      if (Object.keys(cache).length > 0) {
        setLocalVideoCache(cache);
        if (programData && userData) {
          setUserLocalData({ ...userData, offlineProgram: programData.id_ });
        }
        setIsOfflineAccess(true);
      }
    } catch (e) {
      console.log("ProgramOverView: downloadMedias failed. Error:", e);
    }
    setShowModals(false);
    setIsDownloading(false);
  };

  const switchDownload = async () => {
    if (!isOfflineAccess) {
      setShowModals(true);
    } else {
      await clearCache();
      setIsOfflineAccess(!isOfflineAccess);
      setLocalVideoCache({});
      userData && setUserLocalData({ ...userData, offlineProgram: undefined });
    }
  };

  const handleSessionPressed = (_level: any, _session: any) => {
    if (shouldAllowUserToProceed()) {
      //Do not allow user to open session that is ahead of their
      //current session
      if (!isCompleted) {
        if (_level > level) {
          return;
        }
        if (_level === level && _session > session) {
          return;
        }
      }
      //Show interstitial page
      navigation.navigate(RouteNames.STOP_INTERSTITIAL, {
        id: id,
        image: image,
        interstitial: getInterstitial(_level, _session),
        vas: getVAS(_level, _session),
        psfs: getPSFS(_level, _session),
        level: _level,
        session: _session,
        data: programData,
      });
    } else {
      setShowPopup(true);
    }
  };

  const getInterstitial = (_level = null, _session = null) => {
    let exercises;
    if (!_level || !_session) {
      exercises = programData?.phyxes[level]?.[session]?.pages ?? [];
    } else {
      exercises = programData?.phyxes[_level]?.[_session]?.pages ?? [];
    }

    const inter = exercises.filter(
      (exercise) => exercise.type === "interstitial",
    );

    //We only ask for first index because there will be interstitial
    //at the end of exercises as well
    const interstitial = inter[0];
    return interstitial;
  };

  const getVAS = (_level = null, _session = null) => {
    let exercises;
    if (!_level || !_session) {
      exercises = programData.phyxes[level]?.[session]?.pages ?? [];
    } else {
      exercises = programData.phyxes[_level]?.[_session]?.pages ?? [];
    }
    return exercises.filter((exercise) => exercise.type === "vas");
  };

  const getPSFS = (_level = null, _session = null) => {
    let exercises;
    if (!_level || !_session) {
      exercises = programData.phyxes[level]?.[session]?.pages ?? [];
    } else {
      exercises = programData.phyxes[_level]?.[_session]?.pages ?? [];
    }
    return exercises.filter((exercise) => exercise.type === "psfs");
  };

  //check if user should be allowed to proceed further
  const shouldAllowUserToProceed = () => {
    if (isPhyxablePlus(userData?.userProfile.subscription)) {
      return true;
    }
    //If user has free plan
    if (session === 0) {
      return true;
    }
    return false;
  };

  //Style and view for 'Read more' button
  const renderTruncatedFooter = (handlePress: any) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Read more
      </Text>
    );
  };

  //Style and view for 'Show less' button
  const renderRevealedFooter = (handlePress: any) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Show less
      </Text>
    );
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  //Rendering each video content within sessions
  //Rendering each video content within sessions
  const ProgramVideos = ({ item, showLock, showCheckmark }: any) => {
    return (
      <View>
        {item.session_title ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text numberOfLines={1} style={styles.videoTitle}>
                {item.session_title}
              </Text>
              <Text style={styles.videoSubHeading}>
                {item?.time
                  ? `${item?.time} secs`
                  : item?.reps
                  ? `${item?.reps} reps x ${item.sets} sets`
                  : null}
              </Text>
            </View>
            {showLock ? (
              <View
                style={{
                  marginLeft: 20,
                  alignItems: "flex-end",
                }}
              >
                <MaterialIcons name="lock-outline" size={20} color="black" />
              </View>
            ) : null}
            {showCheckmark ? (
              <View
                style={{
                  marginLeft: 20,
                  alignItems: "flex-end",
                }}
              >
                <MaterialIcons
                  name="check-circle-outline"
                  size={20}
                  color={colors.green}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        {item.session_title ? <Divider style={styles.divider} /> : null}
      </View>
    );
  };

  //Rendering each session within levels
  const ProgramSessions = ({ item, levelNum }: any) => {
    let showLock = true;
    let showCheckmark = false;

    if (!isUserPaid) {
      if (levelNum === 1 && item.session === 1) {
        showLock = false;
      }
    } else {
      showLock = false;
    }
    if (isCompleted) {
      showCheckmark = true;
    } else if (levelNum < level) {
      showCheckmark = true;
    } else if (levelNum === level) {
      if (session + 1 === totalSessions) {
        showCheckmark = true;
      } else if (item.session <= session) {
        showCheckmark = true;
      }
    }
    return (
      <TouchableOpacity
        style={styles.sessionContainer}
        onPress={() => handleSessionPressed(levelNum, item.session - 1)}
      >
        <View style={styles.sessionHeading}>
          <Text style={styles.sessionTitle}>Session {item.session}</Text>
        </View>
        <View style={styles.videoContainer}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={item.pages}
            renderItem={({ item }) => (
              <ProgramVideos
                item={item}
                showLock={showLock}
                showCheckmark={showCheckmark}
              />
            )}
            keyExtractor={(i) => item.pages.indexOf(i)}
            ListEmptyComponent={<Loading />}
          />
        </View>
      </TouchableOpacity>
    );
  };

  //Rendering level within program
  const ProgramLevels = ({ level }: { level: number }): JSX.Element => {
    return (
      <List.Accordion
        title={`Level ${level}`}
        id={level}
        style={styles.list}
        theme={{ colors: { text: colors.black } }}
      >
        <FlatList
          showsVerticalScrollIndicator={false}
          data={programData.phyxes[level]}
          renderItem={({ item }) => (
            <ProgramSessions item={item} levelNum={level} />
          )}
          keyExtractor={(item) => `${item.session}`}
          ListEmptyComponent={<Loading />}
        />
      </List.Accordion>
    );
  };

  const handlePopupClick = () => {
    setShowPopup(false);
    navigation.navigate("Account", {
      screen: "Subscription",
    });
  };

  const handlePopUpClose = () => {
    setShowPopup(false);
  };

  return (
    <SafeAreaView>
      <ScrollView style={{ height: "100%", backgroundColor: "white" }}>
        {programData ? (
          <View>
            {/* Image background heading view */}
            <ProgramBanner
              title={programData.title}
              image={image}
              handleGoBack={handleGoBack}
            />

            {/* Content view */}
            <View style={styles.contentView}>
              {/* OverView */}
              <Text style={styles.sectionHeading}>Overview</Text>
              <ReadMore
                numberOfLines={5}
                renderTruncatedFooter={renderTruncatedFooter}
                renderRevealedFooter={renderRevealedFooter}
              >
                <Text>{programData.overview}</Text>
              </ReadMore>

              {/* Equipment */}
              <View>
                <Text style={styles.sectionHeading}>Equipment</Text>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={equipment}
                  numColumns={2}
                  renderItem={({ item }) => (
                    <EquipmentItem name={item.name} image={item.image} />
                  )}
                  keyExtractor={(item) => item.name}
                  ListEmptyComponent={<Loading />}
                />
              </View>

              {/* Button view */}
              <View style={styles.button}>
                <Button
                  text="START SESSION"
                  backgroundColor={colors.primaryColor}
                  customStyle={{
                    alignSelf: "center",
                  }}
                  onPressCallback={handleContinue}
                />
              </View>
              {isMobile && (
                <View style={styles.offlineAccess}>
                  <Text>Access Program Offline</Text>
                  <TouchableOpacity
                    disabled={isDownloading}
                    onPress={switchDownload}
                  >
                    <Image
                      style={{ width: 48, height: 48 }}
                      source={isOfflineAccess ? toggleOn : toggleOff}
                    />
                  </TouchableOpacity>
                </View>
              )}
              {/* Program overview */}
              <View style={{ marginBottom: 20 }}>
                <Text style={styles.sectionHeading}>Program Overview</Text>

                {isUserPaid !== null ? (
                  <List.AccordionGroup>
                    <FlatList
                      showsVerticalScrollIndicator={false}
                      data={programData.level}
                      renderItem={({ item }) => <ProgramLevels level={item} />}
                      keyExtractor={(item) =>
                        `${programData.level.indexOf(item)}`
                      }
                      ListEmptyComponent={<Loading />}
                    />
                  </List.AccordionGroup>
                ) : null}
              </View>
            </View>
            <Overlay
              isVisible={showModals}
              onBackdropPress={() => {}}
              overlayStyle={{
                width: isTablet ? "50%" : "90%",
                borderRadius: 20,
                padding: 0,
                margin: 0,
              }}
              ModalComponent={Modal}
            >
              <OfflineModal
                onClose={() => setShowModals(false)}
                onContinue={handleDownload}
                loading={isDownloading}
              />
            </Overlay>
          </View>
        ) : (
          <Loading />
        )}
      </ScrollView>
      <PhyxablePlus
        show={showPopup}
        handlePopupClick={handlePopupClick}
        handlePopUpClose={handlePopUpClose}
      />
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  contentView: {
    paddingHorizontal: 20,
  },
  sectionHeading: {
    fontWeight: "400",
    fontSize: 16,
    color: colors.secondaryColor,
    marginTop: 20,
    marginBottom: 10,
  },
  list: {
    backgroundColor: "white",
    paddingHorizontal: 0,
  },
  sessionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  sessionHeading: {
    width: "40%",
  },
  videoContainer: {
    width: "60%",
  },
  videoTitle: {
    fontSize: 15,
    fontWeight: "700",
    color: colors.secondaryColor,
    marginBottom: 5,
  },
  videoSubHeading: {
    fontSize: 13,
    color: colors.textColor,
  },
  divider: {
    marginBottom: 5,
    marginTop: 5,
  },
  sessionTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    marginLeft: 7,
  },
  button: {
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 10,
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    gap: 24,
  },
  indicator: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  offlineAccess: {
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
});
