import { CUSTOM_PHYX_BANNER_URI } from "@env";
import { Ionicons } from "@expo/vector-icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Card, Text } from "react-native-paper";
import Container from "typedi";
import { APP_IMAGES } from "../../../assets/images";
import { UserService } from "../../../services/user/user.service";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext, ModalData, UserData } from "../../provider/AuthProvider";
import Loader from "../../screens/utils/Loading";
import LoadingOver from "../../screens/utils/LoadingOver";
import {
  CustomWorkouts,
  IProgram,
  ProgramType,
} from "../../types/program.types";
import ProgramChip from "../Program/programChip";
import CardContent from "./CardContent";
import EmptyCard from "./EmptyCard";
import { getData, handleRemovePhyx } from "./carouselCommon";
enum EnumType {
  removePhyx = "removePhyx",
}
export default function WebCarouselDesktop({ navigation }: any) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const { userData, setUserLocalData, allPrograms, setShowModals, setModalData } = useContext(
    AuthContext,
  ) as any;

  const [programIndex, setProgramIndex] = useState(0);
  const [data, setData] = useState<(CustomWorkouts | IProgram)[]>([]);
  const [loading, setLoading] = useState(false);
  const [changing, setChanging] = useState(false);
  const userService = Container.get(UserService);

  const removePhyx = async (
    item: CustomWorkouts | IProgram,
    userData: UserData,
    setUserLocalData: any,
  ) => {
    const action1 = async () => {
      setLoading(true);
      handleRemovePhyx(item, userData as UserData, setUserLocalData).then(
        () => {
          setLoading(false);
        },
      );
    };

    const action2 = () => {
      if (setShowModals) {
        setShowModals(false);
      }
    };

    const modalData = getModalData(EnumType.removePhyx, action1, action2);
    if (setModalData) {
      setModalData(modalData);
    }
    if (setShowModals) {
      setShowModals(true);
    }
  };

  const getModalData = (
    type: EnumType,
    button1Action: any,
    button2Action: any,
  ) => {
    const modalData: ModalData = {
      headerImage: "https://phyxableprograms.s3.amazonaws.com/prevent.svg",
      bodyImage: "",
      footerImage: "",
      title: "Are you sure?",
      bodyTitle:
        type === EnumType.removePhyx
          ? "Are you sure you want to remove this program?"
          : "",
      bodyList: [],
      button1: "Yes",
      button2: "Close",
      type: "notification",
      button1Action: () => button1Action(),
      button2Action: () => button2Action(),
    };

    return modalData;
  };


  useEffect(() => {
    setLoading(true);
    getData(
      userData as UserData,
      setData,
      allPrograms as IProgram[],
      setUserLocalData,
    ).then(() => {
      setLoading(false);
    });
  }, [userData?.phyxes]);

  useEffect(() => {
    setLoading(true);
    getData(
      userData as UserData,
      setData,
      allPrograms as IProgram[],
      setUserLocalData,
    ).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (userData?.currentProgram) {
      const originalPhyxes = [...userData?.phyxes];
      const indexofCurrentProgram = originalPhyxes.indexOf(
        (userData?.currentProgram as IProgram).id_ ||
        (userData?.currentProgram as CustomWorkouts)._id,
      );
      if (indexofCurrentProgram > 0) {
        const temp = originalPhyxes[0];
        originalPhyxes[0] = originalPhyxes[indexofCurrentProgram];
        originalPhyxes[indexofCurrentProgram] = temp;
        setUserLocalData({
          ...userData,
          phyxes: originalPhyxes,
        });
        setProgramIndex(0);
        userService.updateOrderOfPhyxes(
          userData?.id as string,
          originalPhyxes,
          userData?.email as string,
        );
      }
    }
    if (!userData?.currentProgram && !userData?.phyxes?.length) {
      setData([]);
    }
  }, [userData?.currentProgram]);

  const renderProgram = ({ item }: { item: IProgram }) => {
    return (
      <TouchableOpacity
        key={Math.random()}
        style={{
          flex: 1,
        }}
        onPress={() => {
          //@ts-ignore
          setUserLocalData({ ...userData, currentProgram: item });
          navigation.navigate(RouteNames.PROGRAM_MAIN, {
            screen: RouteNames.PROGRAM_OVERVIEW,
            params: {
              id: item?.id,
              image: item?.images?.banner || CUSTOM_PHYX_BANNER_URI,
              data: item,
            },
          });
        }}
      >
        <Ionicons
          name="close"
          size={isTablet ? 60 : 40}
          color={colors.primaryColor}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 100,
          }}
          onPress={() => {
            removePhyx(item, userData as UserData, setUserLocalData);
          }}
        />
        <Card
          elevation={5}
          style={{
            flexGrow: 1,
            flex: 1,
            height: "100%",
            width: "100%",
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: "white",
            position: "relative",
          }}
          contentStyle={{ flexGrow: 1, height: "100%" }}
        >
          <Card.Cover
            source={{ uri: item.images?.banner || CUSTOM_PHYX_BANNER_URI }}
          />
          <Image
            resizeMethod="resize"
            resizeMode="contain"
            style={{
              position: "absolute",
              top: 195 / 2,
              left: "50%",
              transform: [
                { translateX: -width * 0.075 },
                { translateY: -height * 0.2 * 0.5 },
              ],
              width: width * 0.15,
              height: height * 0.2,
            }}
            source={APP_IMAGES.playIcon}
          />
          <CardContent
            title={item.title}
            text={item.overview || ""}
            often={item?.often}
          />
        </Card>
      </TouchableOpacity>
    );
  };

  const renderCustomProgram = ({ item }: { item: CustomWorkouts }) => {
    return (
      <TouchableOpacity
        key={Math.random()}
        style={{
          flex: 1,
        }}
        onPress={() => {
          // Set the current program
          //@ts-ignore
          setUserLocalData({ ...userData, currentProgram: item });
          navigation.navigate(RouteNames.PROGRAM_MAIN, {
            screen: RouteNames.CUSTOMPHYX_OVERVIEW,
            params: {
              id: item?._id,
              image: CUSTOM_PHYX_BANNER_URI,
              data: item,
            },
          });
        }}
      >
        <Ionicons
          name="close"
          size={isTablet ? 60 : 40}
          color={colors.primaryColor}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 100,
          }}
          onPress={() => {
            removePhyx(item, userData as UserData, setUserLocalData);
          }}
        />
        <Card
          elevation={5}
          style={{
            width: "100%",
            height: "100%",
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: "white",
            position: "relative",
          }}
          contentStyle={{ flexGrow: 1, height: "100%" }}
        >
          <Card.Cover source={{ uri: CUSTOM_PHYX_BANNER_URI }} />
          <Image
            resizeMethod="resize"
            resizeMode="contain"
            style={{
              position: "absolute",
              top: 195 / 2,
              left: "50%",
              width: width * 0.15,
              height: height * 0.2,
              transform: [
                { translateX: -width * 0.075 },
                { translateY: -height * 0.2 * 0.5 },
              ],
            }}
            source={APP_IMAGES.playIcon}
          />
          <CardContent
            title={item.program_name}
            text={item.program_overview || ""}
            isCustom
          />
        </Card>
      </TouchableOpacity>
    );
  };

  const changeCustomProgram = useCallback(
    (item: IProgram | CustomWorkouts) => {
      setChanging(true);
      setTimeout(() => {
        setChanging(false);
        //@ts-ignore
        setUserLocalData({ ...userData, currentProgram: item });
      }, 700);
    },
    [setUserLocalData, userData],
  );

  return (
    <>
      {!loading ? (
        <View style={{ flex: 1 }}>
          <Text
            style={{
              alignSelf: "flex-start",
              fontSize: isTablet ? 24 : 18,
              fontWeight: "500",
              marginTop: "5%",
              color: colors.blueColor,
            }}
          >
            Programs
          </Text>
          <View>
            <FlatList
              horizontal
              scrollEnabled={true}
              data={data}
              renderItem={({ item, index }: { item: any; index: number }) => {
                if (item?.type === ProgramType.Custom) {
                  return (
                    <RenderCustomChip
                      item={item}
                      _index={index}
                      programIndex={programIndex}
                      changeCustomProgram={changeCustomProgram}
                    />
                  );
                } else {
                  return (
                    <RenderChip
                      item={item}
                      _index={index}
                      programIndex={programIndex}
                      changeCustomProgram={changeCustomProgram}
                    />
                  );
                }
              }}
              keyExtractor={(item: IProgram | CustomWorkouts) =>
                item.id || item._id
              }
              style={{
                alignSelf: "center",
                marginTop: 10,
                width: "100%",
                alignContent: "center",
              }}
              ListEmptyComponent={() => (
                <View
                  style={{
                    alignSelf: "center",
                    padding: 10,
                  }}
                >
                  <EmptyCard navigation={navigation} />
                </View>
              )}
              ListFooterComponent={() => {
                return (
                  userData?.currentProgram && (
                    <Ionicons
                      name="add"
                      size={30}
                      color={colors.blueColor}
                      style={{
                        alignSelf: "center",
                      }}
                      onPress={() => {
                        navigation.navigate(RouteNames.PROGRAM_ALL);
                      }}
                    />
                  )
                );
              }}
            />
          </View>

          <View
            style={{
              position: "relative",
              flex: 1,
              marginVertical: 20,
            }}
          >
            {data && data[programIndex] && (
              <>
                {data[programIndex]?.type === ProgramType.Custom
                  ? renderCustomProgram({ item: data[programIndex] })
                  : renderProgram({ item: data[programIndex] })}
              </>
            )}
            {changing && <LoadingOver />}
          </View>
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            marginTop: "25%",
          }}
        >
          <Loader />
        </View>
      )}
    </>
  );
}

type RenderChipProps = {
  item: IProgram;
  _index: number;
  programIndex: number;
  changeCustomProgram: (item: IProgram | CustomWorkouts) => void;
};

const RenderChip = ({
  item,
  _index,
  programIndex,
  changeCustomProgram,
}: RenderChipProps) => {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  return (
    <ProgramChip
      item={item}
      selected={_index === programIndex}
      onChipPressedCallback={changeCustomProgram}
    >
      {item.title}
    </ProgramChip>
  );
};

type RenderCustomChipProps = {
  item: CustomWorkouts;
  _index: number;
  programIndex: number;
  changeCustomProgram: (item: IProgram | CustomWorkouts) => void;
};

const RenderCustomChip = ({
  item,
  _index,
  programIndex,
  changeCustomProgram,
}: RenderCustomChipProps) => {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  return (
    <ProgramChip
      item={item}
      selected={_index === programIndex}
      onChipPressedCallback={changeCustomProgram}
    >
      {item.program_name}
    </ProgramChip>
  );
};
