import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { IEquipment } from "../../types/types";
import RenderImage from "../Common/renderImage";

export default function EquipmentItem({
  name,
  image,
}: IEquipment): JSX.Element {
  const [img, setImg] = useState("");

  useEffect(() => {
    if (image) {
      setImg(`https://phyxableprograms.s3.amazonaws.com/${image}.svg`);
    }
  }, [image]);

  return (
    <View style={styles.container}>
      {img ? (
        <RenderImage
          uri={img}
          size={{ width: 40, height: 40 }}
          style={[{ width: 40, height: 40 }]}
        />
      ) : (
        <Image
          style={styles.imagePlaceholder}
          source={require("../../../assets/images/placeholder.jpg")}
        />
      )}
      <Text style={styles.item}>{name}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "50%",
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  item: {
    textTransform: "capitalize",
    marginLeft: 10,
  },
  imagePlaceholder: {
    width: 40,
    height: 40,
  },
});
